import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

export const contactData = {
  email: "info@epik.uz",
  phone: "+82-10-3221-1626",
  address: {
    en: "Seolleung-ro, Gangnam-gu, Seoul, Republic of Korea",
    ko: "서울특별시 강남구 선릉로 615",
    uz: "Seolleung-ro, Gangnam-gu, Seoul, Republic of Korea",
    ru: "Соллынг-ро, район Каннам, Сеул, Республика Корея",
  },
};
export const services = [
  {
    id: 1,
    title: {
      en: "Web Development",
      ko: "웹 개발",
      uz: "Veb Dasturlash",
      ru: "Веб-разработка",
    },
    cardDescription: {
      en: "Custom websites and web applications that work perfectly for Central Asian businesses, from corporate sites to e-commerce platforms",
      ko: "기업 사이트부터 전자상거래 플랫폼까지 중앙아시아 기업에 완벽하게 맞는 맞춤형 웹사이트 및 웹 애플리케이션",
      uz: "Markaziy Osiyo bizneslari uchun maxsus moslashtirilgan korporativ saytlar va elektron savdo platformalarini ishlab chiqish",
      ru: "Индивидуальные веб-сайты и веб-приложения, идеально подходящие для бизнеса в Центральной Азии, от корпоративных сайтов до платформ электронной коммерции",
    },
    detailPage: {
      title: {
        en: "Web Development Solutions",
        ko: "웹 개발 솔루션",
        uz: "Veb Dasturlash Yechimlari",
        ru: "Решения для веб-разработки",
      },
      summary: {
        en: "We craft high-performance websites and applications tailored for Central Asian businesses, blending global web standards with regional market expertise. From visually striking corporate sites to robust e-commerce platforms, we deliver solutions designed to enhance user engagement, improve operational efficiency, and drive measurable growth.",
        ko: "중앙아시아 기업을 위해 글로벌 웹 표준과 지역 시장 전문 지식을 결합한 고성능 웹사이트 및 애플리케이션을 제작합니다. 시각적으로 돋보이는 기업 사이트에서 강력한 전자상거래 플랫폼까지, 사용자 참여를 높이고 운영 효율성을 향상시키며 실질적인 성장을 촉진하는 솔루션을 제공합니다.",
        uz: "Markaziy Osiyo bizneslari uchun yuqori unumli veb-saytlar va ilovalarni ishlab chiqamiz. Global standartlarni mahalliy bozor talablariga moslashtirib, mijozlar bilan o'zaro aloqani yaxshilash va o'sishga xizmat qiluvchi yechimlarni taqdim etamiz.",
        ru: "Мы создаем высокопроизводительные сайты и приложения, адаптированные под бизнес Центральной Азии, сочетая мировые веб-стандарты с опытом регионального рынка. От ярких корпоративных сайтов до надежных платформ электронной коммерции — наши решения улучшают взаимодействие с пользователем, повышают операционную эффективность и обеспечивают рост.",
      },
      keyFeatures: {
        en: [
          "Corporate Websites & Landing Pages",
          "E-commerce Solutions",
          "Custom Web Applications",
          "Content Management Systems",
          "Website Maintenance & Support",
        ],
        ko: [
          "기업 웹사이트 및 랜딩 페이지",
          "전자상거래 솔루션",
          "맞춤형 웹 애플리케이션",
          "콘텐츠 관리 시스템",
          "웹사이트 유지보수 및 지원",
        ],
        uz: [
          "Korporativ saytlar va landing sahifalar",
          "Elektron savdo yechimlari",
          "Maxsus veb ilovalar",
          "Kontent boshqaruv tizimlari",
          "Veb-sayt texnik xizmat va qo'llab-quvvatlash",
        ],
        ru: [
          "Корпоративные сайты и лендинги",
          "Решения для электронной коммерции",
          "Индивидуальные веб-приложения",
          "Системы управления контентом",
          "Поддержка и обслуживание сайтов",
        ],
      },
      process: {
        en: [
          "Requirements Analysis",
          "Design & Prototyping",
          "Development",
          "Testing & QA",
          "Launch & Support",
        ],
        ko: [
          "요구사항 분석",
          "디자인 및 프로토타이핑",
          "개발",
          "테스트 및 품질 보증",
          "출시 및 지원",
        ],
        uz: [
          "Talablarni tahlil qilish",
          "Dizayn va prototip yaratish",
          "Dasturlash",
          "Test va sifatni ta'minlash",
          "Ishga tushirish va qo'llab-quvvatlash",
        ],
        ru: [
          "Анализ требований",
          "Дизайн и прототипирование",
          "Разработка",
          "Тестирование и контроль качества",
          "Запуск и поддержка",
        ],
      },
      technologies: {
        en: ["React", "Node.js", "MongoDB", "AWS", "Next.js"],
        ko: ["React", "Node.js", "MongoDB", "AWS", "Next.js"],
        uz: ["React", "Node.js", "MongoDB", "AWS", "Next.js"],
        ru: ["React", "Node.js", "MongoDB", "AWS", "Next.js"],
      },
      heroImg: "/assets/img/service/web.png",
    },
    icon: "/assets/img/service/web.png",
  },
  {
    id: 2,
    title: {
      en: "Mobile App Development",
      ko: "모바일 앱 개발",
      uz: "Mobil Ilova Dasturlash",
      ru: "Разработка мобильных приложений",
    },
    cardDescription: {
      en: "Native and cross-platform mobile apps designed for Central Asian users, with local payment integration and multilingual support",
      ko: "현지 결제 통합 및 다국어 지원이 포함된 중앙아시아 사용자를 위한 네이티브 및 크로스 플랫폼 모바일 앱",
      uz: "Markaziy Osiyo foydalanuvchilari uchun mahalliy to'lov tizimlari va ko'p tilli qo'llab-quvvatlashga ega ilovalar",
      ru: "Нативные и кроссплатформенные мобильные приложения для пользователей Центральной Азии с интеграцией локальных платежей и поддержкой нескольких языков",
    },
    detailPage: {
      title: {
        en: "Mobile App Development",
        ko: "모바일 앱 개발",
        uz: "Mobil Ilova Yaratish",
        ru: "Разработка мобильных приложений",
      },
      summary: {
        en: "We build intuitive, secure, and scalable mobile apps that resonate with Central Asian audiences. Our apps integrate local payment systems, provide multilingual support, and deliver seamless user experiences on both native and cross-platform frameworks. With a focus on regional user needs, we create apps that bridge global innovation and local accessibility.",
        ko: "중앙아시아 사용자와 공감할 수 있는 직관적이고 안전하며 확장 가능한 모바일 앱을 구축합니다. 현지 결제 시스템을 통합하고 다국어 지원을 제공하며, 네이티브 및 크로스 플랫폼 프레임워크에서 매끄러운 사용자 경험을 제공합니다. 지역 사용자 요구 사항에 초점을 맞춘 앱으로 글로벌 혁신과 현지 접근성을 연결합니다.",
        uz: "Markaziy Osiyo auditoriyasi ehtiyojlarini hisobga olib, qulay, xavfsiz va kengaytiriladigan mobil ilovalarni yaratamiz. Ilovalarimiz mahalliy to'lov tizimlari bilan integratsiyalangan va ko'p tilli qo'llab-quvvatlashga ega.",
        ru: "Мы создаем интуитивно понятные, безопасные и масштабируемые мобильные приложения, которые находят отклик у аудитории Центральной Азии. Наши приложения интегрируют локальные платежные системы, поддерживают несколько языков и обеспечивают бесшовный пользовательский опыт на нативных и кроссплатформенных платформах. Учитывая потребности региона, мы создаем приложения, которые объединяют глобальные инновации и локальную доступность.",
      },
      keyFeatures: {
        en: [
          "iOS & Android Apps",
          "Cross-Platform Solutions",
          "Local Payment Integration",
          "Offline Functionality",
          "Multi-language Support",
        ],
        ko: [
          "iOS 및 Android 앱",
          "크로스 플랫폼 솔루션",
          "현지 결제 통합",
          "오프라인 기능",
          "다국어 지원",
        ],
        uz: [
          "iOS va Android ilovalari",
          "Kross-platforma yechimlari",
          "Mahalliy to'lov integratsiyasi",
          "Offline funksionallik",
          "Ko'p tilli qo'llab-quvvatlash",
        ],
        ru: [
          "Приложения для iOS и Android",
          "Кроссплатформенные решения",
          "Интеграция локальных платежей",
          "Оффлайн-функциональность",
          "Поддержка нескольких языков",
        ],
      },
      process: {
        en: [
          "Market Research",
          "UX/UI Design",
          "Development",
          "Local Testing",
          "Launch & Maintenance",
        ],
        ko: [
          "시장 조사",
          "UX/UI 디자인",
          "개발",
          "현지 테스트",
          "출시 및 유지보수",
        ],
        uz: [
          "Bozor tadqiqoti",
          "UX/UI dizayn",
          "Dasturlash",
          "Mahalliy testlash",
          "Ishga tushirish va texnik xizmat",
        ],
        ru: [
          "Исследование рынка",
          "UX/UI-дизайн",
          "Разработка",
          "Локальное тестирование",
          "Запуск и обслуживание",
        ],
      },
      technologies: {
        en: ["React Native", "Flutter", "Swift", "Kotlin", "Firebase"],
        ko: ["React Native", "Flutter", "Swift", "Kotlin", "Firebase"],
        uz: ["React Native", "Flutter", "Swift", "Kotlin", "Firebase"],
        ru: ["React Native", "Flutter", "Swift", "Kotlin", "Firebase"],
      },
      heroImg: "/assets/img/service/app-hero.jpg",
    },
    icon: "/assets/img/app.png",
  },
  {
    id: 3,
    title: {
      en: "AI Solutions",
      ko: "AI 솔루션",
      uz: "AI Yechimlari",
      ru: "AI Решения",
    },
    cardDescription: {
      en: "Practical AI solutions for Central Asian businesses: chatbots, data analysis, and automation tools with local language support",
      ko: "중앙아시아 기업을 위한 실용적인 AI 솔루션: 현지 언어 지원이 포함된 챗봇, 데이터 분석 및 자동화 도구",
      uz: "Markaziy Osiyo bizneslari uchun: chatbotlar, ma'lumotlarni tahlil qilish va avtomatlashtirish vositalari, mahalliy til qo'llab-quvvatlovi bilan amaliy AI yechimlari",
      ru: "Практичные AI-решения для бизнеса Центральной Азии: чат-боты, анализ данных и инструменты автоматизации с поддержкой локальных языков",
    },
    detailPage: {
      title: {
        en: "AI & Automation Solutions",
        ko: "AI 및 자동화 솔루션",
        uz: "AI va Avtomatlashtirish Yechimlari",
        ru: "AI и Автоматизация",
      },
      summary: {
        en: "We deliver practical AI solutions tailored for Central Asian businesses, combining advanced technology with local insights. Our offerings range from multilingual chatbots to intelligent automation and data-driven decision-making tools. By understanding the regional business landscape, we enable companies to leverage AI for operational efficiency, enhanced customer interaction, and sustained growth.",
        ko: "고급 기술과 현지 통찰력을 결합한 중앙아시아 기업을 위한 실용적인 AI 솔루션을 제공합니다. 다국어 챗봇부터 지능형 자동화 및 데이터 기반 의사결정 도구까지 다양합니다. 지역 비즈니스 환경을 이해하여 기업이 AI를 활용하여 운영 효율성을 높이고 고객 상호 작용을 향상시키며 지속 가능한 성장을 이룰 수 있도록 지원합니다.",
        uz: "Biz Markaziy Osiyo bizneslari uchun ilg'or texnologiyalarni mahalliy bilimlar bilan birlashtirgan amaliy AI yechimlarini taqdim etamiz. Bizning xizmatlarimiz ko'p tilli chatbotlardan aqlli avtomatlashtirish va ma'lumotlarga asoslangan qaror qabul qilish vositalarigacha kengayadi. Mintaqaviy biznes muhitini tushunib, AI orqali samaradorlikni oshirish, mijozlar bilan muloqotni kuchaytirish va barqaror o'sishni ta'minlaymiz.",
        ru: "Мы предоставляем практичные AI-решения, адаптированные под бизнес Центральной Азии, сочетая передовые технологии с локальными знаниями. Наши предложения включают многоязычные чат-боты, интеллектуальную автоматизацию и инструменты принятия решений на основе данных. Понимая региональный бизнес-контекст, мы помогаем компаниям использовать AI для повышения эффективности, улучшения взаимодействия с клиентами и устойчивого роста.",
      },
      keyFeatures: {
        en: [
          "Multilingual Chatbots",
          "Business Process Automation",
          "Data Analysis & Insights",
          "Custom AI Solutions",
          "Integration Services",
        ],
        ko: [
          "다국어 챗봇",
          "비즈니스 프로세스 자동화",
          "데이터 분석 및 인사이트",
          "맞춤형 AI 솔루션",
          "통합 서비스",
        ],
        uz: [
          "Ko'p tilli chatbotlar",
          "Biznes jarayonlarini avtomatlashtirish",
          "Ma'lumotlarni tahlil qilish va tavsiyalar",
          "Maxsus AI yechimlari",
          "Integratsiya xizmatlari",
        ],
        ru: [
          "Многоязычные чат-боты",
          "Автоматизация бизнес-процессов",
          "Анализ данных и аналитика",
          "Индивидуальные AI-решения",
          "Интеграционные услуги",
        ],
      },
      process: {
        en: [
          "Need Assessment",
          "Solution Design",
          "Development & Training",
          "Integration",
          "Monitoring & Optimization",
        ],
        ko: [
          "필요성 평가",
          "솔루션 설계",
          "개발 및 교육",
          "통합",
          "모니터링 및 최적화",
        ],
        uz: [
          "Talablarni baholash",
          "Yechimlarni loyihalash",
          "Dasturlash va o'qitish",
          "Integratsiya",
          "Monitoring va optimizatsiya",
        ],
        ru: [
          "Оценка потребностей",
          "Проектирование решений",
          "Разработка и обучение",
          "Интеграция",
          "Мониторинг и оптимизация",
        ],
      },
      technologies: {
        en: [
          "TensorFlow",
          "PyTorch",
          "OpenAI API",
          "Google Cloud AI",
          "Azure AI",
        ],
        ko: [
          "TensorFlow",
          "PyTorch",
          "OpenAI API",
          "Google Cloud AI",
          "Azure AI",
        ],
        uz: [
          "TensorFlow",
          "PyTorch",
          "OpenAI API",
          "Google Cloud AI",
          "Azure AI",
        ],
        ru: [
          "TensorFlow",
          "PyTorch",
          "OpenAI API",
          "Google Cloud AI",
          "Azure AI",
        ],
      },
      heroImg: "/assets/img/service/ai.png",
      sidebarImg: "/assets/img/service/chatbot.png",
    },
    icon: "/assets/img/service/ai.png",
  },
];

export const serviceFaq = [
  {
    question: {
      en: "How long does it take for you to complete a project?",
      ko: "프로젝트를 완료하는 데 얼마나 걸리나요?",
      uz: "Loyihani tugatish qancha vaqt oladi?",
      ru: "Сколько времени занимает выполнение проекта?",
    },
    answer: {
      en: "The timeline depends on the project scope and complexity. Typically, websites take 4–8 weeks, mobile apps 8–16 weeks, and AI solutions 12–20 weeks. We provide a detailed timeline during the planning stage.",
      ko: "프로젝트의 범위와 복잡성에 따라 달라집니다. 일반적으로 웹사이트는 4~8주, 모바일 앱은 8~16주, AI 솔루션은 12~20주가 소요됩니다. 계획 단계에서 자세한 일정표를 제공합니다.",
      uz: "Vaqt loyihaning hajmi va murakkabligiga bog'liq. Odatda, veb-saytlar 4–8 hafta, mobil ilovalar 8–16 hafta, AI yechimlari esa 12–20 hafta davom etadi. Rejalashtirish bosqichida batafsil jadvalni taqdim etamiz.",
      ru: "Сроки зависят от объема и сложности проекта. Обычно создание веб-сайтов занимает 4–8 недель, мобильных приложений — 8–16 недель, а AI-решений — 12–20 недель. Мы предоставляем детальный график на этапе планирования.",
    },
  },
  {
    question: {
      en: "How much does it cost to work with your agency?",
      ko: "귀사와 협력하는 비용은 얼마나 되나요?",
      uz: "Sizning agentlik bilan ishlash qancha turadi?",
      ru: "Сколько стоит работа с вашим агентством?",
    },
    answer: {
      en: "Costs vary based on the project type and requirements. We offer competitive pricing and provide a transparent quote after understanding your needs. Contact us for a custom estimate.",
      ko: "비용은 프로젝트 유형과 요구 사항에 따라 달라집니다. 경쟁력 있는 가격을 제공하며, 고객의 요구 사항을 파악한 후 명확한 견적을 제공합니다. 맞춤 견적을 위해 문의해 주세요.",
      uz: "Narx loyiha turi va talablariga qarab o'zgaradi. Biz raqobatbardosh narxlarni taklif qilamiz va sizning ehtiyojlaringizni tushunganimizdan so'ng aniq taklifni taqdim etamiz. Maxsus baho olish uchun biz bilan bog'laning.",
      ru: "Стоимость зависит от типа проекта и требований. Мы предлагаем конкурентные цены и предоставляем прозрачную смету после анализа ваших потребностей. Свяжитесь с нами для индивидуального расчета.",
    },
  },
  {
    question: {
      en: "What industries do you specialize in?",
      ko: "어떤 산업 분야를 전문으로 하나요?",
      uz: "Qaysi sohalar bo'yicha ixtisoslashgansiz?",
      ru: "На каких отраслях вы специализируетесь?",
    },
    answer: {
      en: "We specialize in industries like e-commerce, education, healthcare, logistics, and small-to-medium businesses. However, our versatile team can adapt to any industry’s unique needs.",
      ko: "전자상거래, 교육, 헬스케어, 물류 및 중소기업 분야를 전문으로 합니다. 그러나 당사의 다재다능한 팀은 모든 산업의 고유한 요구 사항에 적응할 수 있습니다.",
      uz: "Biz elektron tijorat, ta'lim, sog'liqni saqlash, logistika va kichik-katta biznes sohalariga ixtisoslashganmiz. Shunga qaramay, bizning jamoamiz har qanday sohaning o'ziga xos talablariga moslasha oladi.",
      ru: "Мы специализируемся на таких отраслях, как электронная коммерция, образование, здравоохранение, логистика и малый и средний бизнес. Однако наша команда готова адаптироваться к уникальным потребностям любой отрасли.",
    },
  },
  {
    question: {
      en: "Do you offer support after project delivery?",
      ko: "프로젝트 전달 후 지원을 제공하나요?",
      uz: "Loyiha topshirilgandan keyin qo'llab-quvvatlashni taklif qilasizmi?",
      ru: "Вы предоставляете поддержку после завершения проекта?",
    },
    answer: {
      en: "Yes, we offer post-launch support and maintenance services to ensure your project runs smoothly. Our team is available for updates, troubleshooting, and performance optimizations.",
      ko: "네, 프로젝트 실행 후 원활히 운영될 수 있도록 지원 및 유지보수 서비스를 제공합니다. 당사 팀은 업데이트, 문제 해결 및 성능 최적화를 위해 항상 대기 중입니다.",
      uz: "Ha, biz loyiha ishga tushgandan keyin texnik qo'llab-quvvatlash va xizmatlarni taklif qilamiz. Bizning jamoamiz yangilanishlar, muammolarni hal qilish va ishlashni optimallashtirish uchun doim tayyor.",
      ru: "Да, мы предоставляем поддержку и услуги по обслуживанию после запуска, чтобы ваш проект работал без перебоев. Наша команда готова к обновлениям, устранению неполадок и оптимизации производительности.",
    },
  },
  {
    question: {
      en: "What sets your agency apart from others?",
      ko: "귀사의 차별점은 무엇인가요?",
      uz: "Sizning agentligingizni boshqalardan nimasi bilan ajratib turadi?",
      ru: "Что отличает ваше агентство от других?",
    },
    answer: {
      en: "Our focus on blending global expertise with local insights makes us unique. We emphasize clear communication, tailored solutions, and long-term partnerships to deliver exceptional results.",
      ko: "글로벌 전문성과 현지 통찰력을 결합하는 데 중점을 둔 것이 당사의 차별점입니다. 명확한 소통, 맞춤형 솔루션, 장기적인 파트너십을 강조하여 뛰어난 결과를 제공합니다.",
      uz: "Bizning global tajribani mahalliy bilimlar bilan birlashtirishga e'tiborimiz bizni boshqalardan ajratib turadi. Biz aniq aloqa, moslashtirilgan yechimlar va uzoq muddatli hamkorlikni ta'minlashga intilamiz.",
      ru: "Мы выделяемся благодаря сочетанию глобального опыта и локальных знаний. Мы уделяем особое внимание ясному общению, индивидуальным решениям и долгосрочным партнерствам, обеспечивая превосходные результаты.",
    },
  },
];

export const statistics = [
  {
    name: {
      en: "Projects Completed",
      ko: "완료 프로젝트 수",
      uz: "Bajarilgan loyihalar",
      ru: "Завершенные проекты",
    },
    number: {
      en: "10",
      ko: "10",
      uz: "10",
      ru: "10",
    },
    icon: "assets/img/icon/17.svg",
  },
  {
    name: {
      en: "Clients Served",
      ko: "서비스 제공 고객 수",
      uz: "Xizmat ko'rsatilgan mijozlar",
      ru: "Обслуженные клиенты",
    },
    number: {
      en: "8",
      ko: "8",
      uz: "8",
      ru: "8",
    },
    icon: "assets/img/icon/16.svg",
  },
  {
    name: {
      en: "Countries Served",
      ko: "서비스 제공 국가 수",
      uz: "Xizmat ko'rsatilgan davlatlar",
      ru: "Обслуженные страны",
    },
    number: {
      en: "3",
      ko: "3",
      uz: "3",
      ru: "3",
    },
    icon: "assets/img/icon/location.png",
  },
  {
    name: {
      en: "Languages Supported",
      ko: "지원 언어 수",
      uz: "Qo'llab-quvvatlanadigan tillar",
      ru: "Поддерживаемые языки",
    },
    number: {
      en: "4",
      ko: "4",
      uz: "4",
      ru: "4",
    },
    icon: "assets/img/icon/language.png",
  },
];

export const testimonials = [
  {
    name: "Kim Jumin",
    comment: {
      en: "Epik delivered exceptional value for our WMS project. As a startup, we appreciated their flexible pricing and extensive 6-month maintenance support. Their understanding of both Korean and English made communication seamless. The warehouse app they built has significantly improved our operational efficiency.",
      ko: "Epik은 우리의 WMS 프로젝트에서 탁월한 가치를 제공했습니다. 스타트업으로서 유연한 가격 정책과 6개월간의 포괄적인 유지보수 지원에 큰 도움을 받았습니다. 한국어와 영어 모두에 대한 이해력으로 의사소통이 원활했으며, 개발된 창고 관리 앱으로 운영 효율성이 크게 향상되었습니다.",
      uz: "Epik bizning WMS loyihamiz uchun ajralmas qiymat taqdim etdi. Startap sifatida ularning moslashuvchan narx siyosati va keng qamrovli 6 oylik texnik qo‘llab-quvvatlashni qadrladik. Ularning koreys va ingliz tillaridagi bilimlari muloqotni osonlashtirdi. Ular yaratgan ombor dasturi bizning operatsion samaradorligimizni sezilarli darajada yaxshiladi.",
      ru: "Epik принес значительную ценность для нашего проекта WMS. Будучи стартапом, мы оценили их гибкую ценовую политику и обширную поддержку в течение 6 месяцев. Их знание корейского и английского языков сделало общение беспрепятственным. Разработанное ими приложение для склада значительно улучшило нашу операционную эффективность.",
    },
    companyName: "WorkersHigh",
    position: "CTO",
    avatar: "/assets/img/testimonial/1.png",
  },

  {
    name: "William Rock",
    comment: {
      en: "Epik's expertise in AI development exceeded our expectations with the Avlo project. They successfully built an AI agent that handles complex fiqh Q&A with remarkable accuracy. Their understanding of both technical requirements and cultural sensitivities made them the perfect partner. The ongoing support and optimization have been invaluable.",
      ko: "Epik의 AI 개발 전문성은 Avlo 프로젝트에서 우리의 기대를 뛰어넘었습니다. 복잡한 피크흐 Q&A를 놀라운 정확도로 처리하는 AI 에이전트를 성공적으로 구축했습니다. 기술적 요구사항과 문화적 민감성에 대한 이해력으로 완벽한 파트너였으며, 지속적인 지원과 최적화는 매우 가치있었습니다.",
      uz: "Epik'ning AI rivojlantirishdagi tajribasi Avlo loyihasida kutganlarimizdan ham yuqori bo'ldi. Ular fiqhiy savol-javoblarni ajoyib aniqlik bilan hal qiladigan AI agentini muvaffaqiyatli yaratdilar. Ularning texnik talablar va madaniy nozikliklarni tushunishi ularni mukammal hamkorga aylantirdi. Doimiy qo'llab-quvvatlash va optimallashtirishlar juda muhim ahamiyatga ega bo'ldi.",
      ru: "Экспертиза Epik в разработке ИИ превзошла наши ожидания в проекте Avlo. Они успешно создали ИИ-агента, который с удивительной точностью обрабатывает сложные вопросы и ответы по фихку. Их понимание технических требований и культурных особенностей сделало их идеальным партнером. Постоянная поддержка и оптимизация оказались бесценными.",
    },
    companyName: "HS Soft",
    position: "Technical Director",
    avatar: "/assets/img/testimonial/1.png",
  },
  {
    name: "Jea Jin He",
    comment: {
      en: "Papamarket's success is in no small part due to Epik's exceptional web development services. Their team delivered a robust e-commerce platform that handles our complex requirements effortlessly. The attention to detail in UI/UX design and mobile responsiveness has significantly improved our conversion rates.",
      ko: "파파마켓의 성공은 Epik의 뛰어난 웹 개발 서비스 덕분입니다. 복잡한 요구사항을 손쉽게 처리하는 강력한 이커머스 플랫폼을 구축해 주었습니다. UI/UX 디자인과 모바일 반응성에 대한 세심한 주의로 전환율이 크게 향상되었습니다.",
      uz: "Papamarket'ning muvaffaqiyati Epik'ning ajoyib veb-dasturlash xizmatlariga bog'liq. Ularning jamoasi murakkab talablarimizni osonlik bilan bajara oladigan kuchli elektron tijorat platformasini yaratdi. UI/UX dizayni va mobil moslanuvchanligi bo'yicha ko'rsatilgan e'tibor bizning konversiya ko'rsatkichlarimizni sezilarli darajada oshirdi.",
      ru: "Успех Papamarket во многом зависит от исключительных услуг веб-разработки Epik. Их команда создала мощную платформу для электронной коммерции, которая без труда справляется с нашими сложными требованиями. Внимание к деталям в дизайне UI/UX и мобильной адаптивности значительно повысило наши конверсионные показатели.",
    },
    companyName: "Papamarket",
    position: "Product Manager",
    avatar: "/assets/img/testimonial/2.png",
  },
];

export const workProcesses = [
  {
    title: {
      en: "Planning and Designing",
      ko: "기획 및 디자인",
      uz: "Rejalashtirish va dizayn",
      ru: "Планирование и дизайн",
    },
    description: {
      en: "Let's transform your idea into a solid plan. Through collaborative sessions, we'll craft your project roadmap, design concepts, and detailed budget breakdown within the first week.",
      ko: "아이디어를 확실한 계획으로 전환합니다. 협업 세션을 통해 첫 주 안에 프로젝트 로드맵, 디자인 콘셉트, 상세 예산 내역을 수립합니다.",
      uz: "Keling, g'oyangizni mustahkam rejaga aylantiramiz. Hamkorlik sessiyalari orqali birinchi hafta ichida loyiha yo'l xaritasi, dizayn tushunchalari va batafsil byudjetni ishlab chiqamiz.",
      ru: "Давайте превратим вашу идею в четкий план. В течение первой недели мы разработаем дорожную карту проекта, концепции дизайна и детальную смету в рамках совместных сессий.",
    },
    lineSrc: "assets/img/about/29.png",
    icon: "assets/img/process/talk.png",
  },
  {
    title: {
      en: "Blueprint and MVP",
      ko: "청사진 및 MVP",
      uz: "Chizma va MVP",
      ru: "План и MVP",
    },
    description: {
      en: "We'll create your project blueprint and develop a Minimum Viable Product in 2-3 weeks. You'll see the core features come to life through interactive prototypes and get a clear development timeline.",
      ko: "2-3주 안에 프로젝트 청사진을 만들고 최소 기능 제품(MVP)을 개발합니다. 인터랙티브 프로토타입으로 핵심 기능을 확인하고 명확한 개발 일정을 제시합니다.",
      uz: "2-3 hafta ichida loyihaning chizmasini yaratamiz va minimal ishlaydigan mahsulot (MVP) ishlab chiqamiz. Interaktiv prototiplar orqali asosiy funksiyalarni ko'rishingiz va aniq ishlab chiqish jadvalini olishingiz mumkin.",
      ru: "В течение 2-3 недель мы создадим план проекта и разработаем минимально жизнеспособный продукт (MVP). Вы сможете увидеть основные функции в интерактивных прототипах и получите четкий график разработки.",
    },
    lineSrc: "assets/img/about/30.png",
    icon: "assets/img/process/planning.png",
  },
  {
    title: {
      en: "Developing & Testing",
      ko: "개발 및 테스트",
      uz: "Ishlab chiqish va sinov",
      ru: "Разработка и тестирование",
    },
    description: {
      en: "Watch your full project take shape with our agile development process. You'll get weekly builds to test, bi-weekly progress meetings, and direct access to our development team for quick feedback.",
      ko: "애자일 개발 프로세스로 프로젝트가 완성되는 과정을 확인하세요. 매주 테스트 빌드, 격주 진행 미팅, 그리고 신속한 피드백을 위한 개발팀과의 직접 소통을 제공합니다.",
      uz: "Agile rivojlantirish jarayonimiz orqali loyihangizning shakllanishini kuzating. Sizga har hafta test uchun yangilanishlar, ikki haftalik rivojlanish uchrashuvlari va tezkor fikr-mulohazalar uchun jamoamiz bilan to'g'ridan-to'g'ri bog'lanish imkoniyati taqdim etiladi.",
      ru: "Наблюдайте, как ваш проект обретает форму благодаря нашему гибкому процессу разработки. Вы будете получать еженедельные сборки для тестирования, участвовать в двухнедельных встречах по прогрессу и иметь прямой доступ к нашей команде для оперативной обратной связи.",
    },
    lineSrc: "assets/img/about/29.png",
    icon: "assets/img/process/development.png",
  },
  {
    title: {
      en: "Launch & Maintenance",
      ko: "출시 및 유지보수",
      uz: "Ishga tushirish va texnik xizmat",
      ru: "Запуск и обслуживание",
    },
    description: {
      en: "Deploy with confidence and keep growing. Benefit from our 3-month launch support package, including performance monitoring, bug fixes, and monthly optimization reviews to ensure smooth operation.",
      ko: "자신감 있게 출시하고 지속적으로 성장하세요. 3개월 출시 지원 패키지로 성능 모니터링, 버그 수정, 월간 최적화 리뷰를 통해 원활한 운영을 보장합니다.",
      uz: "Ishonch bilan ishga tushiring va rivojlanishni davom ettiring. 3 oylik ishga tushirishni qo'llab-quvvatlash paketi, shu jumladan unumdorlikni kuzatish, xatolarni tuzatish va oylik optimallashtirish sharhlari orqali muammosiz ishlashni ta'minlang.",
      ru: "Запускайте проект с уверенностью и продолжайте расти. Воспользуйтесь нашим 3-месячным пакетом поддержки, который включает мониторинг производительности, исправление ошибок и ежемесячные обзоры оптимизации для обеспечения бесперебойной работы.",
    },
    icon: "assets/img/process/support.png",
  },
];

// export const projects = [
//   {
//     id: 1,
//     category: [
//       {
//         en: "Web Development",
//         ko: "웹 개발",
//         uz: "Veb dasturlash",
//         sortValue: "1",
//       },
//       {
//         en: "Mobile App Development",
//         ko: "모바일 앱 개발",
//         uz: "Mobil ilova dasturlash",
//         sortValue: "2",
//       },
//     ],
//     title: {
//       en: "Enterprise Warehouse Management System",
//       ko: "기업용 창고 관리 시스템",
//       uz: "Korxonalar uchun Omborxona Boshqaruv Tizimi",
//     },
//     details: {
//       support: {
//         type: {
//           en: "24/7 Premium Support",
//           ko: "24/7 프리미엄 지원",
//           uz: "24/7 Premium Qo‘llab-quvvatlash",
//         },
//         sla: {
//           responseTime: "< 1 hour",
//           availability: "99.9%",
//         },
//         maintenance: {
//           schedule: {
//             en: "Monthly updates and security patches",
//             ko: "월간 업데이트 및 보안 패치",
//             uz: "Oylik yangilanishlar va xavfsizlik yamalari",
//           },
//         },
//       },
//       overview: {
//         en: "Comprehensive warehouse management solution handling 2,000+ daily inventory movements and 100+ order deliveries for a South Korean retail company",
//         ko: "일일 2,000건 이상의 재고 이동과 100건 이상의 주문 배송을 처리하는 종합 창고 관리 솔루션",
//         uz: "Kuniga 2000+ inventar harakatlari va 100+ buyurtma yetkazib berishlarni boshqaruvchi omborxona boshqaruv tizimi",
//       },
//       industry: {
//         en: "Retail & Distribution",
//         ko: "소매 및 유통",
//         uz: "Savdo va Taqsimlash",
//       },
//       duration: {
//         timeframe: "6 months +",
//         from: "2023-10",
//         to: "2024-5",
//       },
//       location: {
//         en: "South Korea",
//         ko: "대한민국",
//         uz: "Janubiy Koreya",
//       },
//       client: {
//         type: {
//           en: "South Korean Retail Distribution Center",
//           ko: "한국 소매 유통 센터",
//           uz: "Janubiy Koreyaning Chakana Taqsimlash Markazi",
//         },
//         size: {
//           en: "Large Enterprise (200+ employees)",
//           ko: "대기업 (200명 이상)",
//           uz: "Katta korxona (200+ xodim)",
//         },
//       },
//       challenge: {
//         en: "The client needed a robust warehouse management system to handle high-volume inventory operations, complex picking workflows, and real-time order processing while maintaining accurate stock levels and efficient delivery scheduling.",
//         ko: "고객사는 대용량 재고 운영, 복잡한 피킹 워크플로우, 실시간 주문 처리를 처리하면서 정확한 재고 수준과 효율적인 배송 일정을 유지할 수 있는 강력한 창고 관리 시스템이 필요했습니다.",
//         uz: "Mijozga katta hajmdagi inventar operatsiyalarini, murakkab tanlash jarayonlarini va real vaqt rejimida buyurtmalarni qayta ishlashni boshqarish uchun kuchli omborxona boshqaruv tizimi kerak edi.",
//       },
//       solution: {
//         en: "We developed an integrated WMS with real-time inventory tracking, automated picking workflows, and intelligent order management system that optimizes warehouse operations and delivery scheduling.",
//         ko: "실시간 재고 추적, 자동화된 피킹 워크플로우, 창고 운영과 배송 일정을 최적화하는 지능형 주문 관리 시스템을 갖춘 통합 WMS를 개발했습니다.",
//         uz: "Biz real vaqt rejimida inventarni kuzatish, avtomatlashtirilgan tanlash jarayonlari va aqlli buyurtma boshqaruv tizimi bilan integratsiyalashgan WMS ishlab chiqdik.",
//       },
//       architecture: {
//         diagram: "/assets/img/projects/1",
//         description: {
//           en: "Microservices architecture with distributed caching",
//           ko: "분산 캐싱이 있는 마이크로서비스 아키텍처",
//           uz: "Taqsimlangan keshlash bilan mikroxizmatlar arxitekturasi",
//         },
//       },
//       technologies: [
//         {
//           category: {
//             en: "Frontend",
//             ko: "프론트엔드",
//             uz: "Frontend",
//           },
//           stack: [
//             {
//               name: "React",
//               version: "18.x",
//               icon: "/assets/img/projects/tech-icons/react.png",
//               purpose: {
//                 en: "User interface development with Material UI",
//                 ko: "Material UI를 활용한 사용자 인터페이스 개발",
//                 uz: "Material UI yordamida foydalanuvchi interfeysini yaratish",
//               },
//             },
//             {
//               name: "Mui",
//               version: "6.x",
//               icon: "/assets/img/projects/tech-icons/mui.png",
//               purpose: {
//                 en: "User interface development with Material UI",
//                 ko: "Material UI를 활용한 사용자 인터페이스 개발",
//                 uz: "Material UI yordamida foydalanuvchi interfeysini yaratish",
//               },
//             },
//           ],
//         },
//         {
//           category: {
//             en: "Backend",
//             ko: "백엔드",
//             uz: "Backend",
//           },
//           stack: [
//             {
//               name: "Spring Boot",
//               version: "3.x",
//               icon: "/assets/img/projects/tech-icons/spring.png",
//               purpose: {
//                 en: "Backend API development",
//                 ko: "백엔드 API 개발",
//                 uz: "Backend API ishlab chiqish",
//               },
//             },
//             {
//               name: "MySQL",
//               version: "8.x",
//               icon: "/assets/img/projects/tech-icons/mysql.svg",
//               purpose: {
//                 en: "Database management",
//                 ko: "데이터베이스 관리",
//                 uz: "Ma'lumotlar bazasini boshqarish",
//               },
//             },
//             {
//               name: "Spring jpa",
//               version: "3.x",
//               icon: "/assets/img/projects/tech-icons/jpa.png",
//               purpose: {
//                 en: "Database management",
//                 ko: "데이터베이스 관리",
//                 uz: "Ma'lumotlar bazasini boshqarish",
//               },
//             },
//           ],
//         },
//       ],
//       performance: {
//         keyMetrics: {
//           en: [
//             "2,000+ daily inventory movements processed",
//             "100+ daily order deliveries managed",
//             "99.99% inventory accuracy",
//             "45% reduction in picking time",
//           ],
//           ko: [
//             "일일 2,000건 이상의 재고 이동 처리",
//             "일일 100건 이상의 주문 배송 관리",
//             "99.99% 재고 정확도",
//             "피킹 시간 45% 단축",
//           ],
//           uz: [
//             "Kuniga 2000+ inventar harakatlari qayta ishlangan",
//             "Kuniga 100+ buyurtma yetkazib berish boshqarildi",
//             "99.99% inventar aniqligi",
//             "Tanlash vaqti 45% ga qisqardi",
//           ],
//         },
//       },
//       outputs: [
//         {
//           title: {
//             en: "Deployment Architecture",
//             ko: "배포 아키텍처",
//             uz: "Ishga tushirish arxitekturasi",
//           },
//           description: {
//             en: "Visualization of the deployment environment, showcasing microservices architecture and integration points.",
//             ko: "마이크로서비스 아키텍처 및 통합 지점을 보여주는 배포 환경 시각화.",
//             uz: "Mikroxizmatlar arxitekturasi va integratsiya nuqtalarini ko'rsatuvchi ishga tushirish muhiti tasviri.",
//           },
//           image: "/assets/img/projects/1/architecture.png",
//         },
//         {
//           title: {
//             en: "Dashboard",
//             ko: "대시보드",
//             uz: "Boshqaruv paneli",
//           },
//           description: {
//             en: "Centralized dashboard for monitoring warehouse operations in real-time.",
//             ko: "실시간으로 창고 운영을 모니터링하는 중앙 대시보드.",
//             uz: "Ombor operatsiyalarini real vaqt rejimida kuzatish uchun markazlashtirilgan boshqaruv paneli.",
//           },
//           image: "/assets/img/projects/1/output/dashboard.png",
//         },
//         {
//           title: {
//             en: "Picking Workflow",
//             ko: "피킹 워크플로우",
//             uz: "Mahsulot yig'ish jarayoni",
//           },
//           description: {
//             en: "Streamlined picking workflow with automated batch picking and order sorting features.",
//             ko: "자동화된 배치 피킹 및 주문 분류 기능이 포함된 효율적인 피킹 워크플로우.",
//             uz: "Avtomatlashtirilgan tanlash va buyurtmalarni saralash xususiyatlari bilan optimallashtirilgan mahsulot yig‘ish jarayoni.",
//           },
//           image: "/assets/img/projects/1/output/1.png",
//         },
//         {
//           title: {
//             en: "Categories Management",
//             ko: "카테고리 관리",
//             uz: "Kategoriyalarni boshqarish",
//           },
//           description: {
//             en: "User-friendly interface for managing product categories and hierarchies.",
//             ko: "제품 카테고리와 계층 구조를 관리할 수 있는 사용자 친화적인 인터페이스.",
//             uz: "Mahsulot kategoriyalari va ierarxiyasini boshqarish uchun qulay interfeys.",
//           },
//           image: "/assets/img/projects/1/output/2.png",
//         },
//         {
//           title: {
//             en: "Products Page",
//             ko: "제품 페이지",
//             uz: "Mahsulotlar sahifasi",
//           },
//           description: {
//             en: "Comprehensive view of products with filtering and sorting options.",
//             ko: "필터링 및 정렬 옵션이 포함된 제품의 종합적인 뷰.",
//             uz: "Filtrlash va saralash imkoniyatlari bilan to'liq mahsulot ko'rinishi.",
//           },
//           image: "/assets/img/projects/1/output/3.png",
//         },
//         {
//           title: {
//             en: "Warehouse Products List",
//             ko: "창고 제품 목록",
//             uz: "Ombor mahsulotlari ro'yxati",
//           },
//           description: {
//             en: "A detailed list of all products stored in the warehouse, including stock levels and locations.",
//             ko: "재고 수준 및 위치를 포함한 창고에 보관된 모든 제품의 상세 목록.",
//             uz: "Omborda saqlanayotgan barcha mahsulotlarning batafsil ro'yxati, shu jumladan zaxira darajalari va joylashuvlari.",
//           },
//           image: "/assets/img/projects/1/output/4.png",
//         },
//         {
//           title: {
//             en: "Picking Products",
//             ko: "상품 피킹",
//             uz: "Mahsulotlarni yig'ish",
//           },
//           description: {
//             en: "Interface for managing the picking process, including real-time guidance for workers.",
//             ko: "작업자에게 실시간 가이드를 제공하는 피킹 프로세스 관리 인터페이스.",
//             uz: "Ishchilar uchun real vaqt rejimida ko'rsatmalarni o'z ichiga olgan yig'ish jarayonini boshqarish interfeysi.",
//           },
//           image: "/assets/img/projects/1/output/6.png",
//         },
//       ],
//       features: [
//         {
//           title: {
//             en: "Product Receiving",
//             ko: "상품 입고",
//             uz: "Mahsulotni qabul qilish",
//           },
//           description: {
//             en: "Automated receiving process with barcode scanning and quality control checks",
//             ko: "바코드 스캔 및 품질 관리 검사가 포함된 자동화된 입고 프로세스",
//             uz: "Shtrix kod skaneri va sifat nazorati bilan avtomatlashtirilgan qabul jarayoni",
//           },
//         },
//         {
//           title: {
//             en: "Inventory Management",
//             ko: "재고 관리",
//             uz: "Inventarni boshqarish",
//           },
//           description: {
//             en: "Real-time tracking with automated stock level alerts and inventory optimization",
//             ko: "자동화된 재고 수준 경보 및 재고 최적화가 포함된 실시간 추적",
//             uz: "Ombor darajasi haqida avtomatlashtirilgan ogohlantirishlar va real vaqt rejimida kuzatish",
//           },
//         },
//         {
//           title: {
//             en: "Order Processing",
//             ko: "주문 처리",
//             uz: "Buyurtmalarni qayta ishlash",
//           },
//           description: {
//             en: "Intelligent order routing and batch picking optimization",
//             ko: "지능형 주문 라우팅 및 일괄 피킹 최적화",
//             uz: "Aqlli buyurtma yo‘naltirish va tanlashni optimallashtirish",
//           },
//         },
//         {
//           title: {
//             en: "Delivery Management",
//             ko: "배송 관리",
//             uz: "Yetkazib berishni boshqarish",
//           },
//           description: {
//             en: "Route optimization and real-time delivery tracking",
//             ko: "경로 최적화 및 실시간 배송 추적",
//             uz: "Yo‘nalishni optimallashtirish va real vaqt rejimida kuzatish",
//           },
//         },
//       ],
//       businessImpact: {
//         metrics: [
//           {
//             category: "Operational Efficiency",
//             type: "increase",
//             increase: "65%",
//             timeframe: "6 months",
//             uz: "Operatsion samaradorlik 65% ga oshdi (6 oyda)",
//           },
//           {
//             category: "Labor Costs",
//             type: "decrease",
//             decrease: "35%",
//             timeframe: "6 months",
//             uz: "Mehnat xarajatlari 35% ga qisqardi (6 oyda)",
//           },
//         ],
//         benefits: {
//           en: [
//             "Reduced warehouse operating costs by 35%",
//             "Improved order accuracy to 99.99%",
//             "Decreased average order fulfillment time by 66%",
//             "Optimized warehouse space utilization by 40%",
//           ],
//           ko: [
//             "창고 운영 비용 35% 절감",
//             "주문 정확도 99.99%로 향상",
//             "평균 주문 처리 시간 66% 단축",
//             "창고 공간 활용도 40% 최적화",
//           ],
//           uz: [
//             "Ombor xarajatlari 35% ga qisqardi",
//             "Buyurtma aniqligi 99.99% ga yaxshilandi",
//             "Buyurtmalarni bajarish o‘rtacha vaqti 66% ga qisqardi",
//             "Ombor maydonidan foydalanish samaradorligi 40% ga oshdi",
//           ],
//         },
//       },
//       // Continue adding the "uz" key to remaining fields in a similar way
//     },
//     cardImg: "/assets/img/projects/1/output/dashboard.png",
//   },
// ];

export const projects = [
  {
    id: 1,
    category: [
      {
        en: "Web Development",
        ko: "웹 개발",
        uz: "Veb dasturlash",
        ru: "Веб-разработка",
        sortValue: "1",
      },
      {
        en: "Mobile App Development",
        ko: "모바일 앱 개발",
        uz: "Mobil ilova dasturlash",
        ru: "Разработка мобильных приложений",
        sortValue: "2",
      },
    ],
    title: {
      en: "Enterprise Warehouse Management System",
      ko: "기업용 창고 관리 시스템",
      uz: "Korxonalar uchun Omborxona Boshqaruv Tizimi",
      ru: "Система управления складом для предприятий",
    },
    details: {
      support: {
        type: {
          en: "24/7 Premium Support",
          ko: "24/7 프리미엄 지원",
          uz: "24/7 Premium Qo'llab-quvvatlash",
          ru: "Премиум поддержка 24/7",
        },
        sla: {
          responseTime: "< 1 hour",
          availability: "99.9%",
        },
        maintenance: {
          schedule: {
            en: "Monthly updates and security patches",
            ko: "월간 업데이트 및 보안 패치",
            uz: "Oylik yangilanishlar va xavfsizlik yamalari",
            ru: "Ежемесячные обновления и патчи безопасности",
          },
        },
      },
      overview: {
        en: "Comprehensive warehouse management solution handling 2,000+ daily inventory movements and 100+ order deliveries for a South Korean retail company",
        ko: "일일 2,000건 이상의 재고 이동과 100건 이상의 주문 배송을 처리하는 종합 창고 관리 솔루션",
        uz: "Kuniga 2000+ inventar harakatlari va 100+ buyurtma yetkazib berishlarni boshqaruvchi omborxona boshqaruv tizimi",
        ru: "Комплексное решение для управления складом, обрабатывающее более 2000 складских операций и более 100 заказов ежедневно для южнокорейской розничной компании",
      },
      industry: {
        en: "Retail & Distribution",
        ko: "소매 및 유통",
        uz: "Savdo va Taqsimlash",
        ru: "Розничная торговля и дистрибуция",
      },
      duration: {
        timeframe: "6 months +",
        from: "2023-10",
        to: "2024-5",
      },
      location: {
        en: "South Korea",
        ko: "대한민국",
        uz: "Janubiy Koreya",
        ru: "Южная Корея",
      },
      client: {
        type: {
          en: "South Korean Retail Distribution Center",
          ko: "한국 소매 유통 센터",
          uz: "Janubiy Koreyaning Chakana Taqsimlash Markazi",
          ru: "Распределительный центр розничной торговли в Южной Корее",
        },
        size: {
          en: "Large Enterprise (200+ employees)",
          ko: "대기업 (200명 이상)",
          uz: "Katta korxona (200+ xodim)",
          ru: "Крупное предприятие (более 200 сотрудников)",
        },
      },
      challenge: {
        en: "The client needed a robust warehouse management system to handle high-volume inventory operations, complex picking workflows, and real-time order processing while maintaining accurate stock levels and efficient delivery scheduling.",
        ko: "고객사는 대용량 재고 운영, 복잡한 피킹 워크플로우, 실시간 주문 처리를 처리하면서 정확한 재고 수준과 효율적인 배송 일정을 유지할 수 있는 강력한 창고 관리 시스템이 필요했습니다.",
        uz: "Mijozga katta hajmdagi inventar operatsiyalarini, murakkab tanlash jarayonlarini va real vaqt rejimida buyurtmalarni qayta ishlashni boshqarish uchun kuchli omborxona boshqaruv tizimi kerak edi.",
        ru: "Клиенту требовалась надежная система управления складом для обработки большого объема складских операций, комплексных процессов комплектации и обработки заказов в реальном времени при сохранении точного учета запасов и эффективного планирования доставки.",
      },
      solution: {
        en: "We developed an integrated WMS with real-time inventory tracking, automated picking workflows, and intelligent order management system that optimizes warehouse operations and delivery scheduling.",
        ko: "실시간 재고 추적, 자동화된 피킹 워크플로우, 창고 운영과 배송 일정을 최적화하는 지능형 주문 관리 시스템을 갖춘 통합 WMS를 개발했습니다.",
        uz: "Biz real vaqt rejimida inventarni kuzatish, avtomatlashtirilgan tanlash jarayonlari va aqlli buyurtma boshqaruv tizimi bilan integratsiyalashgan WMS ishlab chiqdik.",
        ru: "Мы разработали интегрированную систему управления складом (WMS) с отслеживанием запасов в реальном времени, автоматизированными процессами комплектации и интеллектуальной системой управления заказами, оптимизирующей складские операции и планирование доставки.",
      },
      architecture: {
        diagram: "/assets/img/projects/1",
        description: {
          en: "Microservices architecture with distributed caching",
          ko: "분산 캐싱이 있는 마이크로서비스 아키텍처",
          uz: "Taqsimlangan keshlash bilan mikroxizmatlar arxitekturasi",
          ru: "Микросервисная архитектура с распределенным кешированием",
        },
      },
      technologies: [
        {
          category: {
            en: "Frontend",
            ko: "프론트엔드",
            uz: "Frontend",
            ru: "Фронтенд",
          },
          stack: [
            {
              name: "React",
              version: "18.x",
              icon: "/assets/img/projects/tech-icons/react.png",
              purpose: {
                en: "User interface development with Material UI",
                ko: "Material UI를 활용한 사용자 인터페이스 개발",
                uz: "Material UI yordamida foydalanuvchi interfeysini yaratish",
                ru: "Разработка пользовательского интерфейса с использованием Material UI",
              },
            },
            {
              name: "Mui",
              version: "6.x",
              icon: "/assets/img/projects/tech-icons/mui.png",
              purpose: {
                en: "User interface development with Material UI",
                ko: "Material UI를 활용한 사용자 인터페이스 개발",
                uz: "Material UI yordamida foydalanuvchi interfeysini yaratish",
                ru: "Разработка пользовательского интерфейса с использованием Material UI",
              },
            },
          ],
        },
        {
          category: {
            en: "Backend",
            ko: "백엔드",
            uz: "Backend",
            ru: "Бэкенд",
          },
          stack: [
            {
              name: "Spring Boot",
              version: "3.x",
              icon: "/assets/img/projects/tech-icons/spring.png",
              purpose: {
                en: "Backend API development",
                ko: "백엔드 API 개발",
                uz: "Backend API ishlab chiqish",
                ru: "Разработка API бэкенда",
              },
            },
            {
              name: "MySQL",
              version: "8.x",
              icon: "/assets/img/projects/tech-icons/mysql.svg",
              purpose: {
                en: "Database management",
                ko: "데이터베이스 관리",
                uz: "Ma'lumotlar bazasini boshqarish",
                ru: "Управление базой данных",
              },
            },
            {
              name: "Spring jpa",
              version: "3.x",
              icon: "/assets/img/projects/tech-icons/jpa.png",
              purpose: {
                en: "Database management",
                ko: "데이터베이스 관리",
                uz: "Ma'lumotlar bazasini boshqarish",
                ru: "Управление базой данных",
              },
            },
          ],
        },
      ],
      performance: {
        keyMetrics: {
          en: [
            "2,000+ daily inventory movements processed",
            "100+ daily order deliveries managed",
            "99.99% inventory accuracy",
            "45% reduction in picking time",
          ],
          ko: [
            "일일 2,000건 이상의 재고 이동 처리",
            "일일 100건 이상의 주문 배송 관리",
            "99.99% 재고 정확도",
            "피킹 시간 45% 단축",
          ],
          uz: [
            "Kuniga 2000+ inventar harakatlari qayta ishlangan",
            "Kuniga 100+ buyurtma yetkazib berish boshqarildi",
            "99.99% inventar aniqligi",
            "Tanlash vaqti 45% ga qisqardi",
          ],
          ru: [
            "Обработка более 2000 складских операций ежедневно",
            "Управление более 100 доставками заказов в день",
            "99.99% точность инвентаризации",
            "Сокращение времени комплектации на 45%",
          ],
        },
      },
      outputs: [
        {
          title: {
            en: "Deployment Architecture",
            ko: "배포 아키텍처",
            uz: "Ishga tushirish arxitekturasi",
            ru: "Архитектура развертывания",
          },
          description: {
            en: "Visualization of the deployment environment, showcasing microservices architecture and integration points.",
            ko: "마이크로서비스 아키텍처 및 통합 지점을 보여주는 배포 환경 시각화.",
            uz: "Mikroxizmatlar arxitekturasi va integratsiya nuqtalarini ko'rsatuvchi ishga tushirish muhiti tasviri.",
            ru: "Визуализация среды развертывания, демонстрирующая микросервисную архитектуру и точки интеграции.",
          },
          image: "/assets/img/projects/1/architecture.png",
        },
        {
          title: {
            en: "Dashboard",
            ko: "대시보드",
            uz: "Boshqaruv paneli",
            ru: "Панель управления",
          },
          description: {
            en: "Centralized dashboard for monitoring warehouse operations in real-time.",
            ko: "실시간으로 창고 운영을 모니터링하는 중앙 대시보드.",
            uz: "Ombor operatsiyalarini real vaqt rejimida kuzatish uchun markazlashtirilgan boshqaruv paneli.",
            ru: "Централизованная панель мониторинга складских операций в реальном времени.",
          },
          image: "/assets/img/projects/1/output/dashboard.png",
        },
        {
          title: {
            en: "Picking Workflow",
            ko: "피킹 워크플로우",
            uz: "Mahsulot yig'ish jarayoni",
            ru: "Процесс комплектации",
          },
          description: {
            en: "Streamlined picking workflow with automated batch picking and order sorting features.",
            ko: "자동화된 배치 피킹 및 주문 분류 기능이 포함된 효율적인 피킹 워크플로우.",
            uz: "Avtomatlashtirilgan tanlash va buyurtmalarni saralash xususiyatlari bilan optimallashtirilgan mahsulot yig'ish jarayoni.",
            ru: "Оптимизированный процесс комплектации с автоматизированной пакетной комплектацией и сортировкой заказов.",
          },
          image: "/assets/img/projects/1/output/1.png",
        },
        {
          title: {
            en: "Categories Management",
            ko: "카테고리 관리",
            uz: "Kategoriyalarni boshqarish",
            ru: "Управление категориями",
          },
          description: {
            en: "User-friendly interface for managing product categories and hierarchies.",
            ko: "제품 카테고리와 계층 구조를 관리할 수 있는 사용자 친화적인 인터페이스.",
            uz: "Mahsulot kategoriyalari va ierarxiyasini boshqarish uchun qulay interfeys.",
            ru: "Удобный интерфейс для управления категориями товаров и их иерархией.",
          },
          image: "/assets/img/projects/1/output/2.png",
        },
        {
          title: {
            en: "Products Page",
            ko: "제품 페이지",
            uz: "Mahsulotlar sahifasi",
            ru: "Страница товаров",
          },
          description: {
            en: "Comprehensive view of products with filtering and sorting options.",
            ko: "필터링 및 정렬 옵션이 포함된 제품의 종합적인 뷰.",
            uz: "Filtrlash va saralash imkoniyatlari bilan to'liq mahsulot ko'rinishi.",
            ru: "Полный обзор товаров с возможностями фильтрации и сортировки.",
          },
          image: "/assets/img/projects/1/output/3.png",
        },
        {
          title: {
            en: "Warehouse Products List",
            ko: "창고 제품 목록",
            uz: "Ombor mahsulotlari ro'yxati",
            ru: "Список складских товаров",
          },
          description: {
            en: "A detailed list of all products stored in the warehouse, including stock levels and locations.",
            ko: "재고 수준 및 위치를 포함한 창고에 보관된 모든 제품의 상세 목록.",
            uz: "Omborda saqlanayotgan barcha mahsulotlarning batafsil ro'yxati, shu jumladan zaxira darajalari va joylashuvlari.",
            ru: "Подробный список всех товаров на складе, включая уровни запасов и места хранения.",
          },
          image: "/assets/img/projects/1/output/4.png",
        },
        {
          title: {
            en: "Picking Products",
            ko: "상품 피킹",
            uz: "Mahsulotlarni yig'ish",
            ru: "Комплектация товаров",
          },
          description: {
            en: "Interface for managing the picking process, including real-time guidance for workers.",
            ko: "작업자에게 실시간 가이드를 제공하는 피킹 프로세스 관리 인터페이스.",
            uz: "Ishchilar uchun real vaqt rejimida ko'rsatmalarni o'z ichiga olgan yig'ish jarayonini boshqarish interfeysi.",
            ru: "Интерфейс управления процессом комплектации с указаниями для работников в реальном времени.",
          },
          image: "/assets/img/projects/1/output/6.png",
        },
      ],
      features: [
        {
          title: {
            en: "Product Receiving",
            ko: "상품 입고",
            uz: "Mahsulotni qabul qilish",
            ru: "Приёмка товара",
          },
          description: {
            en: "Automated receiving process with barcode scanning and quality control checks",
            ko: "바코드 스캔 및 품질 관리 검사가 포함된 자동화된 입고 프로세스",
            uz: "Shtrix kod skaneri va sifat nazorati bilan avtomatlashtirilgan qabul jarayoni",
            ru: "Автоматизированный процесс приёмки со сканированием штрих-кодов и проверкой качества",
          },
        },
        {
          title: {
            en: "Inventory Management",
            ko: "재고 관리",
            uz: "Inventarni boshqarish",
            ru: "Управление запасами",
          },
          description: {
            en: "Real-time tracking with automated stock level alerts and inventory optimization",
            ko: "자동화된 재고 수준 경보 및 재고 최적화가 포함된 실시간 추적",
            uz: "Ombor darajasi haqida avtomatlashtirilgan ogohlantirishlar va real vaqt rejimida kuzatish",
            ru: "Отслеживание в реальном времени с автоматическими оповещениями об уровне запасов и оптимизацией складских запасов",
          },
        },
        {
          title: {
            en: "Order Processing",
            ko: "주문 처리",
            uz: "Buyurtmalarni qayta ishlash",
            ru: "Обработка заказов",
          },
          description: {
            en: "Intelligent order routing and batch picking optimization",
            ko: "지능형 주문 라우팅 및 일괄 피킹 최적화",
            uz: "Aqlli buyurtma yo'naltirish va tanlashni optimallashtirish",
            ru: "Интеллектуальная маршрутизация заказов и оптимизация пакетной комплектации",
          },
        },
        {
          title: {
            en: "Delivery Management",
            ko: "배송 관리",
            uz: "Yetkazib berishni boshqarish",
            ru: "Управление доставкой",
          },
          description: {
            en: "Route optimization and real-time delivery tracking",
            ko: "경로 최적화 및 실시간 배송 추적",
            uz: "Yo'nalishni optimallashtirish va real vaqt rejimida kuzatish",
            ru: "Оптимизация маршрутов и отслеживание доставки в реальном времени",
          },
        },
      ],
      businessImpact: {
        metrics: [
          {
            category: "Operational Efficiency",
            type: "increase",
            increase: "65%",
            timeframe: "6 months",
            uz: "Operatsion samaradorlik 65% ga oshdi (6 oyda)",
            ru: "Операционная эффективность выросла на 65% (за 6 месяцев)",
          },
          {
            category: "Labor Costs",
            type: "decrease",
            decrease: "35%",
            timeframe: "6 months",
            uz: "Mehnat xarajatlari 35% ga qisqardi (6 oyda)",
            ru: "Затраты на оплату труда снизились на 35% (за 6 месяцев)",
          },
        ],
        benefits: {
          en: [
            "Reduced warehouse operating costs by 35%",
            "Improved order accuracy to 99.99%",
            "Decreased average order fulfillment time by 66%",
            "Optimized warehouse space utilization by 40%",
          ],
          ko: [
            "창고 운영 비용 35% 절감",
            "주문 정확도 99.99%로 향상",
            "평균 주문 처리 시간 66% 단축",
            "창고 공간 활용도 40% 최적화",
          ],
          uz: [
            "Ombor xarajatlari 35% ga qisqardi",
            "Buyurtma aniqligi 99.99% ga yaxshilandi",
            "Buyurtmalarni bajarish o'rtacha vaqti 66% ga qisqardi",
            "Ombor maydonidan foydalanish samaradorligi 40% ga oshdi",
          ],
          ru: [
            "Снижение операционных расходов склада на 35%",
            "Повышение точности заказов до 99.99%",
            "Сокращение среднего времени выполнения заказа на 66%",
            "Оптимизация использования складского пространства на 40%",
          ],
        },
      },
    },
    cardImg: "/assets/img/projects/1/output/dashboard.png",
  },
  {
    id: 2,
    category: [
      {
        en: "Mobile App Development",
        ko: "모바일 앱 개발",
        uz: "Mobil ilova dasturlash",
        ru: "Разработка мобильных приложений",
        sortValue: "2",
      },
      {
        en: "Educational Technology",
        ko: "교육 기술",
        uz: "Ta'lim texnologiyalari",
        ru: "Образовательные технологии",
        sortValue: "3",
      },
    ],
    title: {
      en: "YodAll - Smart Vocabulary Learning App",
      ko: "요달 - 스마트 어휘 학습 앱",
      uz: "YodAll - Aqlli so'z o'rganish ilovasi",
      ru: "YodAll - Умное приложение для изучения слов",
    },
    details: {
      support: {
        type: {
          en: "Standard Support",
          ko: "표준 지원",
          uz: "Standart qo'llab-quvvatlash",
          ru: "Стандартная поддержка",
        },
        sla: {
          responseTime: "< 24 hours",
          availability: "99.5%",
        },
        maintenance: {
          schedule: {
            en: "Bi-weekly updates and bug fixes",
            ko: "격주 업데이트 및 버그 수정",
            uz: "Har ikki haftada yangilanishlar va xatolarni tuzatish",
            ru: "Обновления и исправления ошибок раз в две недели",
          },
        },
      },
      overview: {
        en: "A comprehensive vocabulary learning application that helps users enhance their language skills through interactive methods, featuring instant translations, pronunciation guides, and personalized learning paths",
        ko: "즉각적인 번역, 발음 가이드, 개인화된 학습 경로를 통해 사용자의 언어 능력 향상을 돕는 종합적인 어휘 학습 애플리케이션",
        uz: "Foydalanuvchilarga tezkor tarjima, talaffuz ko'rsatmalari va shaxsiylashtirilgan o'quv yo'llari orqali til bilimlarini oshirishga yordam beruvchi keng qamrovli so'z o'rganish ilovasi",
        ru: "Комплексное приложение для изучения словарного запаса, помогающее пользователям улучшить языковые навыки с помощью интерактивных методов, мгновенного перевода, руководства по произношению и персонализированных путей обучения",
      },
      industry: {
        en: "Education & Language Learning",
        ko: "교육 및 언어 학습",
        uz: "Ta'lim va til o'rganish",
        ru: "Образование и изучение языков",
      },
      duration: {
        timeframe: "4 months",
        from: "2023-08",
        to: "2023-12",
      },
      location: {
        en: "South Korea",
        ko: "대한민국",
        uz: "Janubiy Koreya",
        ru: "Южная Корея",
      },
      client: {
        type: {
          en: "Individual Entrepreneur",
          ko: "개인 사업자",
          uz: "Individual tadbirkor",
          ru: "Индивидуальный предприниматель",
        },
        size: {
          en: "Small Business (< 10 employees)",
          ko: "소기업 (10명 미만)",
          uz: "Kichik biznes (10 dan kam xodim)",
          ru: "Малый бизнес (менее 10 сотрудников)",
        },
      },
      challenge: {
        en: "The client needed a user-friendly, feature-rich vocabulary learning application that could support multiple languages while maintaining high performance and offering an intuitive admin interface for content management.",
        ko: "고객은 다국어를 지원하면서도 높은 성능을 유지하고 콘텐츠 관리를 위한 직관적인 관리자 인터페이스를 제공하는 사용자 친화적이고 기능이 풍부한 어휘 학습 애플리케이션이 필요했습니다.",
        uz: "Mijozga ko'p tilli qo'llab-quvvatlash, yuqori samaradorlik va kontent boshqaruvi uchun intuitiv admin interfeysi bilan foydalanuvchilarga qulay, ko'p funksiyali so'z o'rganish ilovasi kerak edi.",
        ru: "Клиенту требовалось удобное, многофункциональное приложение для изучения словарного запаса с поддержкой нескольких языков, высокой производительностью и интуитивно понятным интерфейсом администратора для управления контентом.",
      },
      solution: {
        en: "We developed a cross-platform mobile application using React Native and Expo, featuring offline support, real-time translations, and a comprehensive admin panel for content management and user feedback analysis.",
        ko: "React Native와 Expo를 사용하여 오프라인 지원, 실시간 번역, 콘텐츠 관리 및 사용자 피드백 분석을 위한 종합적인 관리자 패널을 갖춘 크로스 플랫폼 모바일 애플리케이션을 개발했습니다.",
        uz: "React Native va Expo yordamida oflayn qo'llab-quvvatlash, real vaqt tarjimasi va kontent boshqaruvi hamda foydalanuvchi fikr-mulohazalarini tahlil qilish uchun keng qamrovli admin paneli bilan platformalararo mobil ilova ishlab chiqdik.",
        ru: "Мы разработали кроссплатформенное мобильное приложение с использованием React Native и Expo, включающее поддержку офлайн-режима, перевод в реальном времени и комплексную панель администратора для управления контентом и анализа обратной связи от пользователей.",
      },
      architecture: {
        diagram: "/assets/img/projects/2",
        description: {
          en: "Cross-platform mobile architecture with offline-first approach",
          ko: "오프라인 우선 접근 방식의 크로스 플랫폼 모바일 아키텍처",
          uz: "Oflayn-birinchi yondashuv bilan platformalararo mobil arxitektura",
          ru: "Кроссплатформенная мобильная архитектура с подходом offline-first",
        },
      },
      technologies: [
        {
          category: {
            en: "Mobile Development",
            ko: "모바일 개발",
            uz: "Mobil dasturlash",
            ru: "Мобильная разработка",
          },
          stack: [
            {
              name: "React Native",
              version: "0.72.x",
              icon: "/assets/img/projects/tech-icons/react-native.svg",
              purpose: {
                en: "Cross-platform mobile development",
                ko: "크로스 플랫폼 모바일 개발",
                uz: "Platformalararo mobil dasturlash",
                ru: "Кроссплатформенная мобильная разработка",
              },
            },
            {
              name: "Expo",
              version: "49.x",
              icon: "/assets/img/projects/tech-icons/expo.svg",
              purpose: {
                en: "Mobile app development and deployment",
                ko: "모바일 앱 개발 및 배포",
                uz: "Mobil ilova ishlab chiqish va joylash",
                ru: "Разработка и развертывание мобильных приложений",
              },
            },
            {
              name: "Expo Router",
              version: "2.x",
              icon: "/assets/img/projects/tech-icons/expo-router.svg",
              purpose: {
                en: "File-based routing for mobile navigation",
                ko: "모바일 네비게이션을 위한 파일 기반 라우팅",
                uz: "Mobil navigatsiya uchun fayl asosidagi yo'naltirish",
                ru: "Файловая маршрутизация для мобильной навигации",
              },
            },
          ],
        },
        {
          category: {
            en: "Backend",
            ko: "백엔드",
            uz: "Backend",
            ru: "Бэкенд",
          },
          stack: [
            {
              name: "Node.js",
              version: "18.x",
              icon: "/assets/img/projects/tech-icons/nodejs.png",
              purpose: {
                en: "Backend server development",
                ko: "백엔드 서버 개발",
                uz: "Backend server ishlab chiqish",
                ru: "Разработка серверной части",
              },
            },
            {
              name: "MongoDB",
              version: "6.x",
              icon: "/assets/img/projects/tech-icons/mongodb.png",
              purpose: {
                en: "Database management",
                ko: "데이터베이스 관리",
                uz: "Ma'lumotlar bazasini boshqarish",
                ru: "Управление базой данных",
              },
            },
          ],
        },
      ],
      performance: {
        keyMetrics: {
          en: [
            "50,000+ words in the database",
            "4.8/5 average user rating",
            "98% crash-free sessions",
            "2.5s average app launch time",
          ],
          ko: [
            "데이터베이스에 50,000개 이상의 단어",
            "평균 사용자 평점 4.8/5",
            "98% 크래시 없는 세션",
            "평균 앱 실행 시간 2.5초",
          ],
          uz: [
            "Ma'lumotlar bazasida 50,000+ so'z",
            "O'rtacha foydalanuvchi reytingi 4.8/5",
            "98% nosozliksiz seanslar",
            "O'rtacha ilova ishga tushish vaqti 2.5 soniya",
          ],
          ru: [
            "Более 50,000 слов в базе данных",
            "Средний рейтинг пользователей 4.8/5",
            "98% сессий без сбоев",
            "Среднее время запуска приложения 2.5 секунды",
          ],
        },
      },
      outputs: [
        {
          title: {
            en: "Mobile App Interface",
            ko: "모바일 앱 인터페이스",
            uz: "Mobil ilova interfeysi",
            ru: "Интерфейс мобильного приложения",
          },
          description: {
            en: "Clean and intuitive user interface for vocabulary learning",
            ko: "어휘 학습을 위한 깔끔하고 직관적인 사용자 인터페이스",
            uz: "So'z o'rganish uchun toza va intuitiv foydalanuvchi interfeysi",
            ru: "Чистый и интуитивно понятный пользовательский интерфейс для изучения словарного запаса",
          },
          image: "/assets/img/projects/2/output/1.png",
        },
        {
          title: {
            en: "Mobile App Interface",
            ko: "모바일 앱 인터페이스",
            uz: "Mobil ilova interfeysi",
            ru: "Интерфейс мобильного приложения",
          },
          description: {
            en: "Clean and intuitive user interface for vocabulary learning",
            ko: "어휘 학습을 위한 깔끔하고 직관적인 사용자 인터페이스",
            uz: "So'z o'rganish uchun toza va intuitiv foydalanuvchi interfeysi",
            ru: "Чистый и интуитивно понятный пользовательский интерфейс для изучения словарного запаса",
          },
          image: "/assets/img/projects/2/output/2.png",
        },
        {
          title: {
            en: "Mobile App Interface",
            ko: "모바일 앱 인터페이스",
            uz: "Mobil ilova interfeysi",
            ru: "Интерфейс мобильного приложения",
          },
          description: {
            en: "Clean and intuitive user interface for vocabulary learning",
            ko: "어휘 학습을 위한 깔끔하고 직관적인 사용자 인터페이스",
            uz: "So'z o'rganish uchun toza va intuitiv foydalanuvchi interfeysi",
            ru: "Чистый и интуитивно понятный пользовательский интерфейс для изучения словарного запаса",
          },
          image: "/assets/img/projects/2/output/3.png",
        },
        {
          title: {
            en: "Mobile App Interface",
            ko: "모바일 앱 인터페이스",
            uz: "Mobil ilova interfeysi",
            ru: "Интерфейс мобильного приложения",
          },
          description: {
            en: "Clean and intuitive user interface for vocabulary learning",
            ko: "어휘 학습을 위한 깔끔하고 직관적인 사용자 인터페이스",
            uz: "So'z o'rganish uchun toza va intuitiv foydalanuvchi interfeysi",
            ru: "Чистый и интуитивно понятный пользовательский интерфейс для изучения словарного запаса",
          },
          image: "/assets/img/projects/2/output/4.png",
        },
        {
          title: {
            en: "Mobile App Interface",
            ko: "모바일 앱 인터페이스",
            uz: "Mobil ilova interfeysi",
            ru: "Интерфейс мобильного приложения",
          },
          description: {
            en: "Clean and intuitive user interface for vocabulary learning",
            ko: "어휘 학습을 위한 깔끔하고 직관적인 사용자 인터페이스",
            uz: "So'z o'rganish uchun toza va intuitiv foydalanuvchi interfeysi",
            ru: "Чистый и интуитивно понятный пользовательский интерфейс для изучения словарного запаса",
          },
          image: "/assets/img/projects/2/output/5.png",
        },
        {
          title: {
            en: "Mobile App Interface",
            ko: "모바일 앱 인터페이스",
            uz: "Mobil ilova interfeysi",
            ru: "Интерфейс мобильного приложения",
          },
          description: {
            en: "Clean and intuitive user interface for vocabulary learning",
            ko: "어휘 학습을 위한 깔끔하고 직관적인 사용자 인터페이스",
            uz: "So'z o'rganish uchun toza va intuitiv foydalanuvchi interfeysi",
            ru: "Чистый и интуитивно понятный пользовательский интерфейс для изучения словарного запаса",
          },
          image: "/assets/img/projects/2/output/6.png",
        },
        {
          title: {
            en: "Mobile App Interface",
            ko: "모바일 앱 인터페이스",
            uz: "Mobil ilova interfeysi",
            ru: "Интерфейс мобильного приложения",
          },
          description: {
            en: "Clean and intuitive user interface for vocabulary learning",
            ko: "어휘 학습을 위한 깔끔하고 직관적인 사용자 인터페이스",
            uz: "So'z o'rganish uchun toza va intuitiv foydalanuvchi interfeysi",
            ru: "Чистый и интуитивно понятный пользовательский интерфейс для изучения словарного запаса",
          },
          image: "/assets/img/projects/2/output/7.png",
        },
      ],
      features: [
        {
          title: {
            en: "Vocabulary Management",
            ko: "어휘 관리",
            uz: "So'z boyligini boshqarish",
            ru: "Управление словарным запасом",
          },
          description: {
            en: "Comprehensive word management with categories and difficulty levels",
            ko: "카테고리 및 난이도 수준이 있는 종합적인 단어 관리",
            uz: "Kategoriyalar va qiyinlik darajalari bilan keng qamrovli so'z boshqaruvi",
            ru: "Комплексное управление словами с категориями и уровнями сложности",
          },
        },
        {
          title: {
            en: "Learning Analytics",
            ko: "학습 분석",
            uz: "O'rganish tahlili",
            ru: "Аналитика обучения",
          },
          description: {
            en: "Detailed progress tracking and performance analytics",
            ko: "상세한 진행 상황 추적 및 성과 분석",
            uz: "Batafsil progress kuzatuvi va samaradorlik tahlili",
            ru: "Детальное отслеживание прогресса и аналитика производительности",
          },
        },
      ],
      businessImpact: {
        metrics: [
          {
            category: "User Engagement",
            type: "increase",
            increase: "85%",
            timeframe: "3 months",
            uz: "Foydalanuvchi faolligi 85% ga oshdi (3 oyda)",
            ru: "Вовлеченность пользователей выросла на 85% (за 3 месяца)",
          },
          {
            category: "App Store Rating",
            type: "increase",
            increase: "4.8",
            timeframe: "3 months",
            uz: "Ilova reytingi 4.8 ga ko'tarildi (3 oyda)",
            ru: "Рейтинг в магазине приложений вырос до 4.8 (за 3 месяца)",
          },
        ],
        benefits: {
          en: [
            "Achieved 100+ downloads in first 3 months",
            "Maintained 85% user retention rate",
            "Reduced content management time by 60%",
            "Achieved 95% positive user feedback",
          ],
          ko: [
            "첫 3개월 동안 100+ 다운로드 달성",
            "85% 사용자 유지율 유지",
            "콘텐츠 관리 시간 60% 단축",
            "95% 긍정적인 사용자 피드백 달성",
          ],
          uz: [
            "Dastlabki 3 oyda 100+ yuklab olishga erishildi",
            "85% foydalanuvchi saqlab qolish darajasiga erishildi",
            "Kontent boshqaruvi vaqti 60% ga qisqardi",
            "95% ijobiy foydalanuvchi fikr-mulohazalariga erishildi",
          ],
          ru: [
            "Достигнуто более 100 скачиваний за первые 3 месяца",
            "Удержание пользователей на уровне 85%",
            "Сокращение времени управления контентом на 60%",
            "Достигнуто 95% положительных отзывов пользователей",
          ],
        },
      },
    },
    cardImg: "/assets/img/projects/2/output/8.png",
  },
  {
    id: 3,
    category: [
      {
        en: "IoT & AI",
        ko: "IoT & AI",
        uz: "IoT va AI",
        ru: "IoT и ИИ",
        sortValue: "1",
      },
      {
        en: "Real-time Monitoring",
        ko: "실시간 모니터링",
        uz: "Real vaqt monitoringi",
        ru: "Мониторинг в реальном времени",
        sortValue: "2",
      },
    ],
    title: {
      en: "Real-time Hazardous Substance Detection System",
      ko: "실시간 유해물질 감지 시스템",
      uz: "Real vaqt xavfli moddalarni aniqlash tizimi",
      ru: "Система обнаружения опасных веществ в реальном времени",
    },
    details: {
      support: {
        type: {
          en: "24/7 Technical Support",
          ko: "24/7 기술 지원",
          uz: "24/7 Texnik yordam",
          ru: "Техническая поддержка 24/7",
        },
        sla: {
          responseTime: "< 30 minutes",
          availability: "99.99%",
        },
        maintenance: {
          schedule: {
            en: "Weekly model updates and system maintenance",
            ko: "주간 모델 업데이트 및 시스템 유지보수",
            uz: "Haftalik model yangilanishlari va tizim texnik xizmati",
            ru: "Еженедельные обновления модели и обслуживание системы",
          },
        },
      },
      overview: {
        en: "AI-powered real-time detection system for bacteria and hazardous substances, integrated with IoT devices for manufacturing facilities in South Korea",
        ko: "대한민국 제조시설을 위한 IoT 장치와 통합된 AI 기반 실시간 박테리아 및 유해물질 감지 시스템",
        uz: "Janubiy Koreyadagi ishlab chiqarish obyektlari uchun IoT qurilmalari bilan integratsiyalashgan AI asosidagi real vaqt bakteriya va xavfli moddalarni aniqlash tizimi",
        ru: "Система обнаружения бактерий и опасных веществ на базе ИИ в реальном времени, интегрированная с устройствами IoT для производственных объектов в Южной Корее",
      },
      industry: {
        en: "Manufacturing & Industrial Safety",
        ko: "제조 및 산업 안전",
        uz: "Ishlab chiqarish va sanoat xavfsizligi",
        ru: "Производство и промышленная безопасность",
      },
      duration: {
        timeframe: "10 months",
        from: "2024-02",
        to: "2024-11",
      },
      location: {
        en: "Incheon Free Economic Zone, South Korea",
        ko: "인천경제자유구역, 대한민국",
        uz: "Incheon Erkin Iqtisodiy Zonasi, Janubiy Koreya",
        ru: "Свободная экономическая зона Инчхон, Южная Корея",
      },
      client: {
        type: {
          en: "IFEZ Manufacturing Facility",
          ko: "IFEZ 제조시설",
          uz: "IFEZ ishlab chiqarish obyekti",
          ru: "Производственный объект IFEZ",
        },
        size: {
          en: "Large Enterprise (500+ employees)",
          ko: "대기업 (500명 이상)",
          uz: "Yirik korxona (500+ xodim)",
          ru: "Крупное предприятие (более 500 сотрудников)",
        },
      },
      challenge: {
        en: "The client required a reliable system for real-time detection of hazardous substances and bacteria in their manufacturing environment, with immediate alerts and comprehensive monitoring capabilities.",
        ko: "고객사는 제조 환경에서 유해물질과 박테리아를 실시간으로 감지하고, 즉각적인 경보와 종합적인 모니터링이 가능한 신뢰성 있는 시스템이 필요했습니다.",
        uz: "Mijozga ishlab chiqarish muhitida xavfli moddalar va bakteriyalarni real vaqtda aniqlash, tezkor ogohlantirish va keng qamrovli monitoring imkoniyatlariga ega ishonchli tizim kerak edi.",
        ru: "Клиенту требовалась надежная система обнаружения опасных веществ и бактерий в производственной среде в реальном времени с мгновенными оповещениями и комплексными возможностями мониторинга.",
      },
      solution: {
        en: "We developed an AI-powered detection system deployed on Raspberry Pi devices, integrated with IoT sensors and a real-time monitoring dashboard for the control center.",
        ko: "라즈베리 파이 장치에 배포된 AI 기반 감지 시스템을 개발하여 IoT 센서와 통합하고, 관제 센터용 실시간 모니터링 대시보드를 구축했습니다.",
        uz: "Biz Raspberry Pi qurilmalarida joylashtirilgan AI asosidagi aniqlash tizimini ishlab chiqdik, IoT sensorlari va nazorat markazi uchun real vaqt monitoring paneli bilan integratsiyalashgan.",
        ru: "Мы разработали систему обнаружения на базе ИИ, развернутую на устройствах Raspberry Pi, интегрированную с датчиками IoT и панелью мониторинга в реальном времени для центра управления.",
      },
      architecture: {
        diagram: "/assets/img/projects/2/architecture.png",
        description: {
          en: "Distributed IoT architecture with edge computing and real-time data processing",
          ko: "엣지 컴퓨팅과 실시간 데이터 처리가 가능한 분산 IoT 아키텍처",
          uz: "Edge computing va real vaqt ma'lumotlarni qayta ishlash bilan taqsimlangan IoT arxitekturasi",
          ru: "Распределенная архитектура IoT с периферийными вычислениями и обработкой данных в реальном времени",
        },
      },
      performance: {
        keyMetrics: {
          en: [
            "95% accuracy in hazard detection",
            "80% reduction in response time",
            "24/7 continuous monitoring capability",
            "Real-time processing of 1000+ sensor data points per minute",
          ],
          ko: [
            "95% 위험 감지 정확도",
            "80% 대응 시간 단축",
            "24/7 연속 모니터링 능력",
            "분당 1000개 이상의 센서 데이터 포인트 실시간 처리",
          ],
          uz: [
            "95% xavfni aniqlash aniqligi",
            "80% javob berish vaqtining qisqarishi",
            "24/7 uzluksiz monitoring imkoniyati",
            "Daqiqada 1000+ sensor ma'lumotlarini real vaqtda qayta ishlash",
          ],
          ru: [
            "95% точность обнаружения опасности",
            "Сокращение времени реагирования на 80%",
            "Возможность непрерывного мониторинга 24/7",
            "Обработка более 1000 точек данных датчиков в минуту в реальном времени",
          ],
        },
      },
      technologies: [
        {
          category: {
            en: "Frontend",
            ko: "프론트엔드",
            uz: "Frontend",
            ru: "Фронтенд",
          },
          stack: [
            {
              name: "React",
              version: "18.x",
              icon: "/assets/img/projects/tech-icons/react.png",
              purpose: {
                en: "Real-time monitoring dashboard",
                ko: "실시간 모니터링 대시보드",
                uz: "Real vaqt monitoring paneli",
                ru: "Панель мониторинга в реальном времени",
              },
            },
          ],
        },
        {
          category: {
            en: "Backend",
            ko: "백엔드",
            uz: "Backend",
            ru: "Бэкенд",
          },
          stack: [
            {
              name: "Spring Boot",
              version: "3.x",
              icon: "/assets/img/projects/tech-icons/spring.png",
              purpose: {
                en: "Backend API and real-time data processing",
                ko: "백엔드 API 및 실시간 데이터 처리",
                uz: "Backend API va real vaqt ma'lumotlarni qayta ishlash",
                ru: "Backend API и обработка данных в реальном времени",
              },
            },
            {
              name: "MySQL",
              version: "8.x",
              icon: "/assets/img/projects/tech-icons/mysql.svg",
              purpose: {
                en: "Data storage and analytics",
                ko: "데이터 저장 및 분석",
                uz: "Ma'lumotlarni saqlash va tahlil qilish",
                ru: "Хранение и анализ данных",
              },
            },
          ],
        },
        {
          category: {
            en: "Infrastructure",
            ko: "인프라",
            uz: "Infratuzilma",
            ru: "Инфраструктура",
          },
          stack: [
            {
              name: "Docker",
              version: "latest",
              icon: "/assets/img/projects/tech-icons/docker.png",
              purpose: {
                en: "Containerization and deployment",
                ko: "컨테이너화 및 배포",
                uz: "Konteynerlashtirish va joylash",
                ru: "Контейнеризация и развертывание",
              },
            },
            {
              name: "AWS EC2",
              version: "latest",
              icon: "/assets/img/projects/tech-icons/ec2.png",
              purpose: {
                en: "Cloud infrastructure",
                ko: "클라우드 인프라",
                uz: "Bulutli infratuzilma",
                ru: "Облачная инфраструктура",
              },
            },
            {
              name: "MQTT",
              version: "3.1.1",
              icon: "/assets/img/projects/tech-icons/mqtt.png",
              purpose: {
                en: "IoT device communication",
                ko: "IoT 장치 통신",
                uz: "IoT qurilma aloqasi",
                ru: "Связь с устройствами IoT",
              },
            },
          ],
        },
      ],
      outputs: [
        {
          title: {
            en: "Main Control Dashboard",
            ko: "메인 관제 대시보드",
            uz: "Asosiy nazorat paneli",
            ru: "Главная панель управления",
          },
          description: {
            en: "Centralized dashboard showing real-time hazard detection status across all monitoring zones",
            ko: "모든 모니터링 구역의 실시간 위험 감지 상태를 보여주는 중앙 대시보드",
            uz: "Barcha monitoring zonalarida real vaqt xavfni aniqlash holatini ko'rsatuvchi markazlashtirilgan boshqaruv paneli",
            ru: "Централизованная панель, показывающая статус обнаружения опасности в реальном времени по всем зонам мониторинга",
          },
          image: "/assets/img/projects/3/output/1.png",
        },
        {
          title: {
            en: "Real-time Analytics",
            ko: "실시간 분석",
            uz: "Real vaqt tahlili",
            ru: "Аналитика в реальном времени",
          },
          description: {
            en: "Live data analysis showing detection trends and environmental parameters",
            ko: "감지 추세와 환경 매개변수를 보여주는 실시간 데이터 분석",
            uz: "Aniqlash tendentsiyalari va atrof-muhit parametrlarini ko'rsatuvchi jonli ma'lumotlar tahlili",
            ru: "Анализ данных в реальном времени, показывающий тенденции обнаружения и параметры окружающей среды",
          },

          image: "/assets/img/projects/3/output/2.png",
        },
        {
          title: {
            en: "Historical Data Analysis",
            ko: "과거 데이터 분석",
            uz: "Tarixiy ma'lumotlar tahlili",
            ru: "Анализ исторических данных",
          },
          description: {
            en: "Detailed historical analysis of detection patterns and system performance metrics",
            ko: "감지 패턴 및 시스템 성능 메트릭의 상세 과거 분석",
            uz: "Aniqlash naqshlari va tizim ishlashi ko'rsatkichlarining batafsil tarixiy tahlili",
            ru: "Детальный исторический анализ паттернов обнаружения и показателей производительности системы",
          },
          image: "/assets/img/projects/3/output/3.png",
        },

        {
          title: {
            en: "Sensor Network Status",
            ko: "센서 네트워크 상태",
            uz: "Sensor tarmog'i holati",
            ru: "Статус сенсорной сети",
          },
          description: {
            en: "Overview of all IoT sensors with their current operational status and health metrics",
            ko: "모든 IoT 센서의 현재 작동 상태 및 상태 메트릭 개요",
            uz: "Barcha IoT sensorlarining joriy ishlash holati va sog'lomlik ko'rsatkichlari",
            ru: "Обзор всех IoT-датчиков с их текущим рабочим состоянием и показателями работоспособности",
          },
          image: "/assets/img/projects/3/output/4.png",
        },
      ],
      features: [
        {
          title: {
            en: "Real-time Detection",
            ko: "실시간 감지",
            uz: "Real vaqt aniqlash",
            ru: "Обнаружение в реальном времени",
          },
          description: {
            en: "AI-powered detection of bacteria and hazardous substances using advanced sensors",
            ko: "고급 센서를 사용한 AI 기반의 박테리아 및 유해물질 감지",
            uz: "Ilg'or sensorlardan foydalangan holda AI asosida bakteriya va xavfli moddalarni aniqlash",
            ru: "Обнаружение бактерий и опасных веществ на базе ИИ с использованием современных датчиков",
          },
        },
        {
          title: {
            en: "Alert System",
            ko: "경보 시스템",
            uz: "Ogohlantirish tizimi",
            ru: "Система оповещения",
          },
          description: {
            en: "Immediate notifications and alerts for detected hazards",
            ko: "감지된 위험에 대한 즉각적인 알림 및 경보",
            uz: "Aniqlangan xavflar uchun tezkor bildirishnomalar va ogohlantirishlar",
            ru: "Мгновенные уведомления и оповещения об обнаруженных опасностях",
          },
        },
      ],
      businessImpact: {
        metrics: [
          {
            category: "Detection Accuracy",
            type: "increase",
            increase: "95%",
            timeframe: "3 months",
          },
          {
            category: "Response Time",
            type: "decrease",
            decrease: "80%",
            timeframe: "3 months",
          },
        ],
        benefits: {
          en: [
            "Enhanced workplace safety through early detection",
            "Reduced risk of contamination incidents",
            "Improved regulatory compliance",
            "Decreased response time to potential hazards",
          ],
          ko: [
            "조기 감지를 통한 작업장 안전성 향상",
            "오염 사고 위험 감소",
            "규제 준수 개선",
            "잠재적 위험에 대한 대응 시간 단축",
          ],
          uz: [
            "Erta aniqlash orqali ish joyi xavfsizligini oshirish",
            "Ifloslanish hodisalari xavfini kamaytirish",
            "Tartibga solish talablariga rioya qilishni yaxshilash",
            "Potentsial xavflarga javob berish vaqtini qisqartirish",
          ],
          ru: [
            "Повышенная безопасность рабочего места благодаря раннему обнаружению",
            "Снижение риска инцидентов загрязнения",
            "Улучшение соответствия нормативным требованиям",
            "Сокращение времени реагирования на потенциальные опасности",
          ],
        },
      },
    },
    cardImg: "/assets/img/projects/3/output/2.png",
  },
];

export const socialLinks = [
  {
    id: 1,
    link: "",
    icon: <FaFacebook />,
  },

  {
    id: 1,
    link: "",
    icon: <FaInstagram />,
  },
  {
    id: 1,
    link: "",
    icon: <FaLinkedin />,
  },
];
