import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
  Stack,
  Box,
  Chip,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { projects } from "../scripts/data";
import { useSelector } from "react-redux";

const ProjectAreaOne = ({
  hasTitle = true,
  hasFilters = false,
  fullList = false,
}) => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.app);
  const [active, setActive] = useState(0);

  const extractCategories = (list = []) => {
    const categoryMap = new Map();
    list.forEach((item) => {
      item?.category?.forEach((cat) => {
        if (!categoryMap.has(cat?.sortValue)) {
          categoryMap.set(cat?.sortValue, cat);
        }
      });
    });
    return Array.from(categoryMap.values());
  };

  const filterProjects = (category) => {
    if (category !== "all") {
      const filteredProjects = projects?.filter((item) => {
        const newItems = item.category.some(
          (cat) => cat?.sortValue === category
        );
        return newItems;
      });
      setData(filteredProjects);
    } else {
      setData(projects);
    }
  };

  useEffect(() => {
    setData(fullList ? projects : projects.slice(0, 5));
    setCategories([
      { en: "All", ko: "모두", sortValue: "all" },
      ...extractCategories(projects),
    ]);
  }, [fullList]);

  const ProjectCard = ({ item, lang }) => {
    const { t } = useTranslation();

    return (
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
          },
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <CardMedia
          component="img"
          height="240"
          image={item?.cardImg || "assets/img/gallery/dashboard.png"}
          alt={item?.title?.[lang]}
          sx={{
            objectFit: "contain",
          }}
        />
        <CardContent sx={{ flexGrow: 1, p: 2.5 }}>
          <Stack spacing={2}>
            <Typography variant="h6" component="h2" gutterBottom>
              {item?.title?.[lang]}
            </Typography>

            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {item?.category?.map((cat, idx) => (
                <Chip
                  key={idx}
                  label={cat[lang]}
                  size="small"
                  sx={{
                    backgroundColor: "rgba(33, 150, 243, 0.1)",
                    color: "primary.main",
                  }}
                />
              ))}
            </Stack>

            {item?.description?.[lang] && (
              <Typography variant="body2" color="text.secondary">
                {item?.description[lang].slice(0, 120)}...
              </Typography>
            )}
          </Stack>
        </CardContent>

        <CardActions sx={{ p: 2, pt: 0 }}>
          <Button
            component={Link}
            to={`/projects/${item?.id}`}
            endIcon={<ArrowForward />}
            sx={{
              ml: "auto",
              "&:hover": {
                backgroundColor: "rgba(33, 150, 243, 0.08)",
              },
            }}
          >
            {t("common.readMore")}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const ViewAllCard = ({ navigate }) => {
    return (
      <Card
        onClick={() => navigate("/projects")}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          p: 4,
          transition: "all 0.3s ease",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
            "& .view-all-icon": {
              transform: "scale(1.1)",
            },
          },
          borderRadius: 2,
        }}
      >
        <Stack spacing={3} alignItems="center" textAlign="center">
          <Box
            className="view-all-icon"
            sx={{
              width: 60,
              height: 60,
              transition: "transform 0.3s ease",
            }}
          >
            <img
              src="assets/img/icon/read-more.png"
              width="100%"
              height="100%"
              alt="View all"
            />
          </Box>

          <Typography variant="h6" color="primary" fontWeight={600}>
            {t("project.view-all-projects")}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ maxWidth: 240 }}
          >
            {t("project.view-all-desc")}
          </Typography>
        </Stack>
      </Card>
    );
  };

  return (
    <div
      className={`project-section pd-top-${hasTitle ? "120" : 90}`}
      id="projects"
    >
      <div className="container">
        {hasTitle && (
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6
                  className="sub-title"
                  style={{ boxShadow: "1px 2px 2px 1px #525151" }}
                >
                  {t("home.projects-chip")}
                </h6>
                <h2 className="title">{t("home.projects-title")}</h2>
              </div>
            </div>
          </div>
        )}

        {hasFilters && (
          <nav className="text-center">
            <div
              className="nav nav-tabs project-nav-tab d-inline-flex mb-5"
              id="nav-tab"
              role="tablist"
            >
              {categories?.map((item, index) => (
                <button
                  key={`item-${index}`}
                  className={`nav-link ${index === active ? "active" : ""}`}
                  id={`nav-tabs${index + 1}-tab`}
                  type="button"
                  onClick={() => {
                    setActive(index);
                    filterProjects(item?.sortValue);
                  }}
                >
                  {item?.[lang]}
                </button>
              ))}
            </div>
          </nav>
        )}

        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-tabs1"
            role="tabpanel"
            aria-labelledby="nav-tabs1-tab"
          >
            <div className="row g-4">
              <AnimatePresence mode="sync">
                {data?.map((item, index) => (
                  <motion.div
                    key={index}
                    className="col-12 col-md-6 col-lg-4"
                    layout
                    initial={{ opacity: 0, x: -400, scale: 0.5 }}
                    animate={{ opacity: 1, x: 0, scale: 1 }}
                    exit={{ opacity: 0, x: 200, scale: 1.2 }}
                    transition={{ duration: 0.5, type: "easeIn" }}
                  >
                    <ProjectCard item={item} lang={lang} />
                  </motion.div>
                ))}

                {!fullList && (
                  <motion.div className="col-12 col-md-6 col-lg-4" layout>
                    <ViewAllCard navigate={navigate} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;
