import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import "./scripts/i18n";
import CenterMode from "./components/SliderTest";
import Projects from "./pages/Projects";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomeTwo />} />

        <Route exact path="/contact" element={<Contact />} />

        <Route exact path="/slider" element={<CenterMode />} />
        <Route path="services">
          <Route exact path="" index element={<Service />} />
          <Route path=":id" element={<ServiceDetails />} />
        </Route>

        <Route path="projects">
          <Route exact path="" index element={<Projects />} />
          <Route exact path=":id" element={<CaseStudyDetails />} />
        </Route>
      </Routes>

      <ScrollToTop smooth color="#246BFD" />
    </BrowserRouter>
  );
}

export default App;
