import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaAngleDoubleDown,
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
  FaDownload,
  FaFileDownload,
} from "react-icons/fa";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { contactData, serviceFaq, services } from "../scripts/data";
import { FaFileArrowDown } from "react-icons/fa6";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import ServiceProcess from "./ProcessStep";

const ServiceDetailsArea = () => {
  const { lang } = useSelector((state) => state.app);
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const serviceId = pathArr[pathArr.length - 1];
  const { t } = useTranslation();
  const [currentService, setCurrentService] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const item = services.find(
      (item) => Number(item?.id) === Number(serviceId)
    );
    setCurrentService(item);
  }, [location.search]);
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className="service-area pd-top-60 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_catagory">
                  <h5 className="widget-title">
                    <FaArrowRight /> {t("common.service-list")}
                  </h5>
                  <ul className="catagory-items">
                    {services.map((item, index) => {
                      return (
                        <li key={`${index}${item?.id}`}>
                          <Link
                            to={`/services/${item?.id}`}
                            className={`${
                              Number(currentService?.id) === Number(item?.id) &&
                              "active bg-primary text-white rounded-0"
                            }`}
                          >
                            {item?.title?.[lang]}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="widget widget_download">
                  <h5 className="widget-title">
                    <FaArrowRight /> Download
                  </h5>
                  <ul>
                    <li>
                      <a
                        href="/assets/pdfs/company-intro.pdf"
                        target="_blank"
                        download="Company-Introduction.pdf"
                      >
                        {" "}
                        {t("button.company-intoduction")} <FaDownload />
                      </a>
                    </li>
                    {/* when service intro pdf is ready */}
                    {/* <li>
                      <a
                        href="/assets/pdfs/company-intro.pdf"
                        target="_blank"
                        download="Company-Introduction.pdf"
                      >
                        {" "}
                        {t("button.service-projects")} <FaDownload />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div
                    // className="thumb"

                    style={
                      {
                        // border: "1px solid #EEEEEE",
                        // backgroundColor: currentService?.id === 3 && "#fd7e7e",
                      }
                    }
                  >
                    <img
                      src={
                        currentService?.detailPage?.heroImg ||
                        "/assets/img/service/7.png"
                      }
                      style={{
                        border: "1px solid #EEEEEE",
                        width: "100%",
                        aspectRatio: 16 / 9,
                      }}
                      alt="img"
                    />
                  </div>
                  <Stack className="details" sx={{ mt: { xs: 1.5, md: 5 } }}>
                    <h2>{currentService?.detailPage?.title?.[lang]}</h2>
                    <p className="mt-4" style={{ whiteSpace: "pre-line" }}>
                      {currentService?.detailPage?.summary?.[lang]}
                    </p>
                    <p>{currentService?.description2?.[lang]}</p>
                    <Box sx={{ mt: 6, mb: 4, width: "100%" }}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 600,
                          color: "#101A29",
                          mb: 3,
                        }}
                      >
                        Key Features
                      </Typography>

                      <Grid container spacing={3}>
                        {currentService?.detailPage?.keyFeatures?.[lang]?.map(
                          (feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <Paper
                                elevation={0}
                                sx={{
                                  p: 3,
                                  height: "100%",
                                  border: "1px solid #EEEEEE",
                                  transition: "all 0.3s ease",
                                  "&:hover": {
                                    transform: "translateY(-5px)",
                                    boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                                    borderColor: "#246BFD",
                                  },
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  alignItems="flex-start"
                                >
                                  <FaCheckCircle
                                    size={24}
                                    style={{
                                      color: "#246BFD",
                                      flexShrink: 0,
                                      marginTop: "4px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color: "#101A29",
                                      fontSize: "1.1rem",
                                      fontWeight: 500,
                                      lineHeight: 1.4,
                                    }}
                                  >
                                    {feature}
                                  </Typography>
                                </Stack>
                              </Paper>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>

                    {/* processes */}
                    <ServiceProcess
                      process={currentService?.detailPage?.process?.[lang]}
                    />
                    <Stack
                      sx={{
                        py: {
                          xs: 4,
                          md: 4,
                        },
                        alignItems: "center",
                        justifyContent: "center",
                        gap: { xs: 1.5, md: 2 },
                        flexDirection: { sm: "row", xs: "column" },
                        backgroundColor: "#f2f5ff",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          height: { md: 52, xs: 40 },
                          minWidth: { xs: "200px", md: 350 },
                          textTransform: "none",
                          backgroundColor: "#246BFD",

                          fontSize: { xs: 15, md: 18 },
                        }}
                        onClick={() => navigate("/contact")}
                      >
                        {t("button.contact")} <ChevronRightIcon />
                      </Button>
                    </Stack>
                  </Stack>
                </div>
              </div>

              <Stack
                className="accordion accordion-inner accordion-icon-left"
                id="accordionExample"
                sx={{ mt: { xs: 1.5, md: 5 } }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    color: "#101A29",
                    mb: 3,
                  }}
                >
                  {t("service.faq")}
                </Typography>

                {serviceFaq?.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading-${index}`}>
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${index}`}
                        aria-expanded={index === 0}
                        aria-controls={`collapse-${index}`}
                      >
                        {item?.question?.[lang]}
                      </button>
                    </h2>
                    <div
                      id={`collapse-${index}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby={`heading-${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {item?.answer?.[lang]}
                      </div>
                    </div>
                  </div>
                ))}
              </Stack>
            </div>
            <div className="contact-page-list mt-5">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                    <div className="media single-contact-list">
                      <div className="media-left">
                        <img src="/assets/img/icon/13.svg" alt="img" />
                      </div>
                      <div className="media-body">
                        <h5>{t("common.phone")}</h5>
                        <h6>{contactData?.phone}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="media single-contact-list">
                      <div className="media-left">
                        <img src="/assets/img/icon/14.svg" alt="img" />
                      </div>
                      <div className="media-body">
                        <h5>{t("common.email")}</h5>
                        <h6>{contactData?.email}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="media single-contact-list">
                      <div className="media-left">
                        <img src="/assets/img/icon/15.svg" alt="img" />
                      </div>
                      <div className="media-body">
                        <h5>{t("home.footer.address")}</h5>
                        <h6>{contactData?.address?.[lang]}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea;
