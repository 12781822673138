import React from "react";
import Swiper from "react-id-swiper";
const Default = () => (
  <Swiper>
    <div>Slide #1</div>
    <div>Slide #2</div>
    <div>Slide #3</div>
    <div>Slide #4</div>
    <div>Slide #5</div>
  </Swiper>
);

export default Default;
