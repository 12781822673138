import React, { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import NavbarTwo from "../components/NavbarTwo";
import FooterThree from "../components/FooterThree";
import ProjectAreaOne from "../components/ProjectAreaOne";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Projects = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { lang } = useSelector((state) => state.app);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={t("pageTitle.project")} />

      <ProjectAreaOne hasTitle={false} hasFilters fullList />
      {/* Service Area One */}
      <ServiceAreaGroup />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default Projects;
