import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import ModalVideo from "react-modal-video";

import {
  Button,
  Stack,
  TextField,
  Paper,
  Typography,
  Box,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const BannerTwo = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await toast.promise(
      new Promise((resolve, reject) => {
        setTimeout(() => {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}api/create-enquiry`,
              {
                companyName: "not entered",
                email: formData?.email || "no email",
                pic: formData?.name,
                ...formData,
              },
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then(resolve)
            .catch(reject);
        }, 330); // Add a delay of 330ms
      }),
      {
        loading: t("home.form.loading"),
        success: t("home.form.success"),
        error: t("home.form.error"),
      }
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const socialLinks = [
    { icon: FacebookIcon, label: "Facebook", color: "#1877F2" },
    { icon: TwitterIcon, label: "Twitter", color: "#1DA1F2" },
    { icon: LinkedInIcon, label: "LinkedIn", color: "#0A66C2" },
    { icon: InstagramIcon, label: "Instagram", color: "#E4405F" },
    { icon: TelegramIcon, label: "Telegram", color: "#0088cc" },
  ];

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div
        className="bg-relative banner-area-2 bg-cover"
        style={{
          backgroundImage: 'url("./assets/img/bg/8.png")',
          minHeight: "100svh",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6
                  className="subtitle"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                  style={{ boxShadow: "1px 2px 2px 1px #525151" }}
                >
                  {t("home.chip.banner")}
                </h6>
                <h2
                  className="title"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  {t("home.banner.title")}
                </h2>
                <p
                  className="content pe-xl-5"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  {t("home.banner.description")}
                </p>
                <Stack
                  sx={{
                    width: { xs: "100%", md: "auto" },
                    flexDirection: { sm: "row" },
                    gap: { xs: 2 },
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      width: "auto",
                      textTransform: "none",
                      backgroundColor: "#246BFD",
                    }}
                    onClick={() => navigate("/services")}
                  >
                    {t("button.view-services")} <ChevronRightIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => navigate("/projects")}
                    sx={{
                      textTransform: "none",
                      border: "2px solid #246BFD",
                      color: "#101A29",
                      ":hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#246BFD",
                      },
                    }}
                  >
                    {t("button.view-portfolio")} <ChevronRightIcon />
                  </Button>
                </Stack>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-9"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <Stack
                className="mt-2 mt-lg-0"
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <img
                    className="banner-animate-img"
                    src="assets/img/banner-2/3.png"
                    alt="img"
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "block",
                    }}
                  />
                  <Stack
                    className="my-form"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: { xs: "90%", sm: "70%", md: "60%" },
                      maxWidth: "400px",
                      minWidth: { xs: "350px", md: "400px" },
                      zIndex: 10,
                    }}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        p: 3,
                        py: { sm: 7, xs: 5 },
                        borderRadius: 2,
                        height: "auto",
                        backgroundColor: "rgba(255, 255, 255, 0.95)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{
                          mb: { xs: 4, md: 3 },
                          fontWeight: 600,
                          color: "#246BFD",
                        }}
                      >
                        {t("home.form.title")}
                      </Typography>
                      <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                          <TextField
                            name="name"
                            label={t("home.form.name")}
                            variant="outlined"
                            fullWidth
                            size={isSm ? "small" : "medium"}
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                          <TextField
                            name="phone"
                            label={t("home.form.phone")}
                            variant="outlined"
                            fullWidth
                            size={isSm ? "small" : "medium"}
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                          <TextField
                            name="email"
                            label={t("common.email")}
                            variant="outlined"
                            fullWidth
                            size={isSm ? "small" : "medium"}
                            value={formData.email}
                            onChange={handleChange}
                          />
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{
                              backgroundColor: "#246BFD",
                              textTransform: "none",
                              py: 1,
                            }}
                          >
                            {t("home.form.submit")}
                          </Button>
                        </Stack>
                      </form>
                      {isSubmitted && (
                        <Box
                          sx={{ mt: 2, textAlign: "center", color: "green" }}
                        >
                          <Typography variant="body2">
                            {t("home.form.success-msg")}
                          </Typography>
                        </Box>
                      )}

                      <Box sx={{ mt: 3, mb: 1 }}>
                        <Divider>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              px: 1,
                              fontSize: "0.875rem",
                            }}
                          >
                            {t("home.form.social-divider")}
                          </Typography>
                        </Divider>
                      </Box>

                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        sx={{ mt: 2 }}
                      >
                        {socialLinks.map((social, index) => (
                          <IconButton
                            key={index}
                            sx={{
                              transition: "all 0.3s ease-in-out",
                              color: social.color,
                              "&:hover": {
                                backgroundColor: `${social.color}15`,
                                transform: "translateY(-2px)",
                              },
                            }}
                          >
                            <social.icon />
                          </IconButton>
                        ))}
                      </Stack>
                    </Paper>
                  </Stack>
                </Box>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerTwo;
