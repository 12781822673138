import React, { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import NavbarTwo from "../components/NavbarTwo";
import FooterThree from "../components/FooterThree";
import ProjectAreaOne from "../components/ProjectAreaOne";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import WorkProcessFour from "../components/WorkProcessFour";

const Service = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={t("common.service")} />

      {/* Service Area One */}
      <ServiceAreaGroup />

      {/* Work Process Two */}
      <WorkProcessFour />
      <ProjectAreaOne hasFilters fullList />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default Service;
