import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { projects, socialLinks } from "../scripts/data";
import { Formatter } from "../scripts/utils";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import {
  Assessment,
  Business,
  Campaign,
  Engineering,
  LocationOn,
  Schedule,
  Source,
  Speed,
  TaskAlt,
  TrendingDown,
  TrendingUp,
  ZoomIn,
} from "@mui/icons-material";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";

const CaseStudyArea = () => {
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.app);
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // State for lightbox
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const pathArr = pathname.split("/");
  const projectId = pathArr[pathArr.length - 1];
  const project = projects.find(
    (item) => Number(item.id) === Number(projectId)
  );

  // Helper function to determine cols based on screen size
  const getGridCols = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return 3;
  };
  const slides =
    project.details.outputs?.map((output) => ({
      src: output.image,
      title: output.title[lang],
      description: output.description[lang],
    })) || [];

  // Handler for opening lightbox
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  return (
    <>
      {/* ====================  Case Study Area start ====================*/}
      <Box className="service-area pt-8" sx={{ mt: { xs: 3, md: 5 } }}>
        <Box className="container">
          <Grid container spacing={4}>
            {/* Left Sidebar */}
            <Grid item xs={12} md={4}>
              <Stack spacing={3}>
                {/* Project Info Card */}
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {t("project.project-info")}
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <Business />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("project.project-info-labels.client")}
                          secondary={project.details.client.type[lang]}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <Schedule />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("project.project-info-labels.duration")}
                          secondary={`${project.details.duration.from} ~ ${project.details.duration.to}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <LocationOn />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("project.project-info-labels.location")}
                          secondary={project.details.location[lang]}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <Source />
                        </ListItemIcon>
                        <ListItemText
                          primary="Industry"
                          secondary={project.details.industry[lang]}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>

                {/* Key Metrics Card */}
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {t("project.key-metrics.title")}
                    </Typography>
                    <List>
                      {project.details.performance.keyMetrics[lang].map(
                        (metric, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <Speed />
                            </ListItemIcon>
                            <ListItemText primary={metric} />
                          </ListItem>
                        )
                      )}
                    </List>
                  </CardContent>
                </Card>

                {/* Support Info Card */}
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {t("project.support.title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {project.details.support.type[lang]}
                    </Typography>
                    <Box mt={2}>
                      <Chip
                        label={`SLA: ${project.details.support.sla.responseTime}`}
                        size="small"
                        color="primary"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>

            {/* Main Content Area */}
            <Grid item xs={12} md={8}>
              <Stack spacing={4}>
                {/* Overview Section */}
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    fontWeight={600}
                    sx={{ color: "#212121" }}
                  >
                    {project.title[lang]}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {project.details.overview[lang]}
                  </Typography>
                  <Divider sx={{ my: 2 }} />

                  {/* Challenge & Solution */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h5" gutterBottom>
                        <Campaign sx={{ mr: 1, verticalAlign: "middle" }} />
                        {t("project.challenge")}
                      </Typography>
                      <Typography variant="body2">
                        {project.details.challenge[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h5" gutterBottom>
                        <TaskAlt sx={{ mr: 1, verticalAlign: "middle" }} />
                        {t("project.solution")}
                      </Typography>
                      <Typography variant="body2">
                        {project.details.solution[lang]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>

                {/* Project Outputs Gallery */}
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    {t("project.outputs")}
                  </Typography>
                  <Box
                    sx={{ width: "100%", height: "100%", overflowY: "hidden" }}
                  >
                    <ImageList
                      variant="standard"
                      cols={getGridCols()}
                      gap={8}
                      sx={{
                        "& .MuiImageListItem-root": {
                          overflow: "hidden",
                          mb: 2, // Add margin bottom to separate items vertically
                        },
                        " ::-webkit-scrollbar": {
                          display: "none",
                        },
                        " ::-webkit-scrollbar-track": {
                          display: "none",
                        },
                      }}
                    >
                      {project.details.outputs?.map((output, index) => (
                        <ImageListItem
                          key={index}
                          sx={{
                            cursor: "pointer",
                            borderRadius: 2,
                            overflow: "hidden",

                            border: "1px solid",
                            borderColor: "divider",
                            transition: "all 0.3s ease-in-out",
                            transform: "translateY(0)",
                            boxShadow: 1,
                            "&:hover": {
                              transform: "translateY(-1px)",
                              boxShadow: 3,
                              "& .MuiImageListItemBar-root": {
                                opacity: 1,
                              },
                            },
                          }}
                          onClick={() => handleImageClick(index)}
                        >
                          <img
                            src={output.image}
                            alt={output.title[lang]}
                            loading="lazy"
                            style={{
                              borderRadius: "4px",
                              objectFit: "contain",
                              maxHeight: "200px",
                            }}
                          />
                          <ImageListItemBar
                            title={output.title[lang]}
                            subtitle={output.description[lang]}
                            sx={{
                              background:
                                "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                              borderBottomLeftRadius: "4px",
                              borderBottomRightRadius: "4px",
                              opacity: 0,
                              transition: "opacity 0.2s ease-in-out",
                            }}
                            actionIcon={
                              <IconButton
                                sx={{ color: "white" }}
                                aria-label={`zoom ${output.title[lang]}`}
                              >
                                <ZoomIn />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                </Paper>

                {/* Technical Details */}
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    <Engineering sx={{ mr: 1, verticalAlign: "middle" }} />
                    {t("project.tech-stack")}
                  </Typography>
                  <Grid container spacing={2}>
                    {project.details.technologies.map((techCategory, index) => (
                      <Grid item xs={12} key={index}>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "rgb(36, 78, 226)" }}
                          gutterBottom
                        >
                          {techCategory.category[lang]}
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {techCategory.stack.map((tech, techIndex) => (
                            <Chip
                              key={techIndex}
                              label={`${tech.name} ${tech.version}`}
                              variant="outlined"
                              icon={
                                <img
                                  src={tech.icon}
                                  alt={tech.name}
                                  style={{
                                    minWidth: 20,
                                    minHeight: 20,
                                    maxHeight: 25,
                                    objectFit: "contain",
                                  }}
                                />
                              }
                            />
                          ))}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>

                {/* Business Impact */}
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    <Assessment sx={{ mr: 1, verticalAlign: "middle" }} />
                    {t("project.business-impact")}
                  </Typography>
                  <Grid container spacing={2}>
                    {project.details.businessImpact.metrics.map(
                      (metric, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Alert
                            severity={
                              metric.type === "decrease" ? "error" : "success"
                            }
                            icon={
                              metric.type === "decrease" ? (
                                <TrendingDown />
                              ) : (
                                <TrendingUp />
                              )
                            }
                          >
                            <Typography variant="subtitle2">
                              {metric.category}
                            </Typography>
                            <Typography variant="h5">
                              {metric.type === "decrease"
                                ? metric.decrease
                                : metric.increase}
                            </Typography>
                            <Typography variant="caption">
                              in {metric.timeframe}
                            </Typography>
                          </Alert>
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Box mt={2}>
                    <Typography variant="h6" gutterBottom>
                      {t("project.key-benefits")}:
                    </Typography>
                    <List>
                      {project.details.businessImpact.benefits[lang].map(
                        (benefit, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <TaskAlt color="success" />
                            </ListItemIcon>
                            <ListItemText primary={benefit} />
                          </ListItem>
                        )
                      )}
                    </List>
                  </Box>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        {/* Lightbox Component */}
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={slides}
          index={currentImageIndex}
          counter={{ style: { top: "unset", bottom: 0 } }}
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Counter]}
          carousel={{ finite: true }}
          styles={{
            root: { "--yarl__color_backdrop": "rgba(52, 52, 52, 0.6)" },
          }}
        />
      </Box>
      {/* ====================  Case Study Area End ====================*/}
    </>
  );
};

export default CaseStudyArea;
