import React from "react";
import { Box, Typography, Stack, Paper } from "@mui/material";
import { FaArrowRight } from "react-icons/fa";

const ProcessStep = ({ number, title, isLast }) => (
  <Stack direction="row" alignItems="center" spacing={2} sx={{ width: "100%" }}>
    <Paper
      elevation={0}
      sx={{
        position: "relative",
        p: 3,
        width: "100%",
        border: "1px solid #EEEEEE",
        borderRadius: 2,
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
          borderColor: "#246BFD",
          "& .step-number": {
            backgroundColor: "#246BFD",
            color: "white",
          },
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          className="step-number"
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: "#f2f5ff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 600,
            fontSize: "1.1rem",
            color: "#246BFD",
            transition: "all 0.3s ease",
          }}
        >
          {number}
        </Box>
        <Typography
          sx={{
            fontSize: "1.1rem",
            fontWeight: 500,
            color: "#101A29",
          }}
        >
          {title}
        </Typography>
      </Stack>
    </Paper>
    {!isLast && (
      <FaArrowRight
        size={24}
        style={{
          color: "#246BFD",
          flexShrink: 0,
          marginLeft: "8px",
          marginRight: "8px",
        }}
      />
    )}
  </Stack>
);

const ServiceProcess = ({ process, lang, title }) => {
  return (
    <Box sx={{ mt: 6, mb: 4, width: "100%" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          color: "#101A29",
          mb: 4,
        }}
      >
        {title || "Our Process"}
      </Typography>

      <Stack
        spacing={2}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {process?.map((step, index) => (
          <ProcessStep
            key={index}
            number={index + 1}
            title={step}
            isLast={index === process.length - 1}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default ServiceProcess;
