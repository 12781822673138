import React, { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServiceDetailsArea from "../components/ServiceDetailsArea";
import NavbarTwo from "../components/NavbarTwo";
import FooterThree from "../components/FooterThree";
import { useLocation, useParams } from "react-router-dom";
import { services } from "../scripts/data";
import { useSelector } from "react-redux";

const ServiceDetails = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { lang } = useSelector((state) => state.app);
  const title = services?.find((i) => Number(i?.id) === Number(id))?.title?.[
    lang
  ];
  return (
    <div>
      {/* Navigation Bar */}
      <NavbarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={title} />

      {/* Service Details Area */}
      <ServiceDetailsArea />

      {/* Footer One */}
      <FooterThree />
    </div>
  );
};

export default ServiceDetails;
