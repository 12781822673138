import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { services } from "../scripts/data";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

const ServiceAreaGroup = () => {
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.app);
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-100">
        <img
          className="position-bottom-left top_image_bounce"
          src="/assets/img/icon/4.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6
              className="sub-title"
              style={{ boxShadow: "1px 2px  2px 1px #525151" }}
            >
              {t("home.chip.services")}
            </h6>
            <h2 className="title">{t("home.service-title")}</h2>
          </div>
          <div className="row">
            {services.map((data, index) => (
              <div className="col-lg-4 col-md-6 d-flex " key={index}>
                <div
                  className="single-service-inner text-center "
                  style={{ flexGrow: 1, height: "100%" }}
                >
                  <div className="thumb">
                    <img
                      src={data.icon}
                      alt="img"
                      style={{
                        height: "297px",
                        width: "100%",
                        // objectFit: index !== 2 ? "contain" : "cover",
                      }}
                    />
                  </div>
                  <Stack
                    className="details"
                    sx={{
                      justifyContent: "space-between",
                      flexGrow: 1,
                      alignItems: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: index === services.length - 1 && "25px",
                      }}
                    >
                      <h5>{data.title?.[lang]}</h5>
                      <p>{data?.cardDescription?.[lang]}</p>
                    </div>

                    <Link
                      className="btn btn-border-base rounded-1"
                      to={`/services/${data?.id}`}
                    >
                      {t("button.read-more")} <FaPlus />
                    </Link>
                  </Stack>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaGroup;
